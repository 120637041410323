import Header from "../../shared/Header/MobileHeader";
import Footer from "../../shared/Footer/MobileFooter";
import './Privacy.css';
import React from 'react';
import Iframe from 'react-iframe';

const MobilePrivacy = () => {

    return (
        <div>
            <Header/>
                <div style={{paddingTop: "10vh"}}></div>
                <Iframe url="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/privacy.html" width="100%" height="500px"/>
            <Footer/>
        </div>
    );
};

export default MobilePrivacy;