// src/components/Header/Header.js
import React from 'react';
import './Header.css';
import { Outlet, Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


export const FanstandLogo = () => {
    return (
        <Col xs='auto' style={{alignItems: "center", justifyContent: "center", position: "relative", paddingLeft: "2.5vw", marginTop: "1.5vw"}}>
            <Link style={{color: 'inherit', textDecoration: 'inherit'}} to="/">
                <Image alt='Square Fanstand app logo - green with a green drawn bench' className="headerImg" src="https://fanstandblobs.blob.core.windows.net/images/fanstandLogoRounded.png"/>
                <span style={{color: 'white', fontSize: '2.5vw', marginLeft: "6vw"}}> Fanstand</span>
            </Link>
        </Col>  
    )
};

export const HeaderNavBar = () => {
    return (
        <Col style={{display: 'flex', flexDirection: 'column-reverse', marginTop: "1.5vw"}}>
            <nav>
                <ul>                                
                    <li >
                        <Link style={{color: 'inherit', textDecoration: 'inherit', fontSize: '1.5vw'}}
                                to="/about">
                        <div className="headerLink">
                            About
                        </div>
                    </Link>
                    </li>
                    <li>
                        <Link style={{color: 'inherit', textDecoration: 'inherit', fontSize: '1.5vw'}}
                                to="/admin">
                        <div className="headerLink">
                            Log In
                        </div>
                    </Link>
                    </li>
                    
                </ul>
            </nav>
        </Col>
    )
};

const Header = () => {
    return (
        <>
            <header id='header'>
                <Row className="correctSizedRow">
                    <FanstandLogo />
                    <HeaderNavBar />
                </Row>
            </header>
        </>
    );
};

export default Header;