// src/components/Footer/Footer.js
import React from 'react';
import './Footer.css';
import {Link} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Instagram from '@mui/icons-material/Instagram';
import LinkedIn from "@mui/icons-material/LinkedIn";
import TikTok from "@mui/icons-material/MusicNote";
import Image from 'react-bootstrap/Image'


const Footer = () => {
    return (
        <div className="footer">
            <Col>
            <Row>
                <Col>
                    <a href="https://www.linkedin.com/company/fanstandapp/people/" target="_blank"
                    rel="noopener noreferrer" style={{paddingBottom: "18px", fontSize: "1vw"}}>
                        <LinkedIn className="footerAppIcon"/>
                        Linkedin
                    </a>
                </Col>
                <Col>
                <a href="https://www.instagram.com/fanstand.app?igsh=bnNrcGxzNGdjeXNj&utm_source=qr" target="_blank"
                    rel="noopener noreferrer" style={{paddingBottom: "18px", fontSize: "1vw"}}>
                        <Instagram className="footerAppIcon"/>
                        Instagram
                    </a>
                </Col>

                <Col>
                    <a href="https://www.tiktok.com/@fanstandapp?_t=8lAScCLAsYM&_r=1" target="_blank"
                    rel="noopener noreferrer" style={{paddingBottom: "18px", fontSize: "1vw"}}>
                        <TikTok className="footerAppIcon"/>
                        TikTok
                    </a>
                </Col>

                <Col>
                    {/* <Image src="https://fanstandblobs.blob.core.windows.net/images/fanstandLogoRounded.png" style={{width: "15vh"}} /> */}
                    <p style={{fontSize: "1vw"}}>© 2024 201 Interactive LLC</p>
                </Col>

                <Col>
                    <Link to="/privacy" style={{bottom: "0", paddingBottom: "18px", fontSize: "1vw"}}>
                        Privacy Policy
                    </Link>
                </Col>
                <Col>
                    <Link to="/acceptable" style={{bottom: "0", paddingBottom: "18px", fontSize: "1vw"}}>
                        Acceptable Use Policy
                    </Link>
                </Col>
                <Col>
                    <Link to="/terms" style={{bottom: "0", paddingBottom: "18px", fontSize: "1vw"}}>
                        Terms of Service
                    </Link>
                </Col>
            </Row>
            </Col>
        </div>
    );
};

export default Footer;
