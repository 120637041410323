import {Circle, MapContainer, Marker, TileLayer, useMap, useMapEvent} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import "./EventManagerMap.css"
import L from 'leaflet'
import {useEffect, useState} from "react";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const EventManagerMap = (props) => {
    const [marker, setMarker] = useState([43.053229868102946, -75.407392200527])

    useEffect(() => {
        const newloc = [props.lat, props.lng]
        setMarker(newloc)
    }, [props.lat, props.lng]);

    function MarkerComponent() {
        const map = useMapEvent('click', (e) => {
            setMarker(e.latlng);
            props.setLat(e.latlng['lat'])
            props.setLng(e.latlng['lng'])
        })

        return (
            <>
                <Marker position={marker}></Marker>
                <Circle center={marker} radius={props.radius} pathOptions={{fillColor: 'red'}}></Circle>
            </>
        )
    }

    return (
        <>
        <MapContainer center={[43.053229868102946, -75.407392200527]} zoom={16} scrollWheelZoom={false}>
            <TileLayer
                attribution='&copy; <a href="https://stadiamaps.com/" target="_blank">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>'
                // url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png?api_key=37298181-cc93-4ddd-826c-306f3959c265"
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MarkerComponent />
        </MapContainer>
        </>
    )
};

export default EventManagerMap;
