import {SERVER_ADDRESS} from "../constants";
import toast from "react-hot-toast";

async function getFutureEvents(token) {
    const endpoint = SERVER_ADDRESS + '/api/v1/event/events/future'
    const toastId = toast.loading("Attempting to load events...")

    return await fetch(endpoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token['accessToken']
        },
    }).then(data => data.json()).then(data => {
        toast.dismiss();
        toast.success("Events were retrieved successfully!", {id: toastId});
        return data;
    }).catch(data => {
        toast.dismiss();
        toast.error("Error occurred when retrieving events.", {id: toastId});
        return ['error'];
    })
}

export default getFutureEvents;