import Button from "react-bootstrap/Button";
import "./formComponents.css"
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import { ReactTyped } from "react-typed";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

export const AnimatedDownArrow = () => {
    return (
        <div style={{textAlign: "center", marginTop: "10vh"}}>
            <div class="arrow arrowSliding delay1"></div>
            <div class="arrow arrowSliding delay2"></div>
            <div class="arrow arrowSliding delay3"></div>
        </div>
    )
}

export const AnimatedDownArrowSingleton = () => {
    return (
        <div style={{textAlign: "center", marginLeft: 'auto', marginRight: "auto", marginTop: "0"}}>
            <div class="arrow arrowSliding delay1"></div>
        </div>
    )
}

export const SubmitButton = ({ text }) => {
    return (
        <Button variant="primary"
            type="submit"
            className='formComponents-eventFormSubmitButton'
            id='submit'
            style={{border: '2px solid #73b564', color: "black"}}
    >
            {text}
        </Button>
    );
}

export const MobileSubmitButton = ({ text }) => {
    return (
        <Button variant="primary"
            type="submit"
            className='formComponents-mobileEventFormSubmitButton'
            id='submit'
            style={{border: '2px solid #73b564', color: "black"}}
    >
            {text}
        </Button>
    );
}

export const ClearButton = ({ text, onClick }) => {
    return (
        <Button
            onClick={onClick}
            className='formComponents-eventFormSubmitButton'
            id='clear'
            style={{border: "2px solid red", color: "black"}}>
            {text}
        </Button>
    );
}

export const EditButton = ({ text, onClick }) => {
    return (
        <Button
            onClick={onClick}
            className='formComponents-eventFormSubmitButton'
            id='edit'
            style={{border: "2px solid olive", color: "black"}}>
            {text}
        </Button>
    );
}

export const EventFormControl = ({ as, type, value, onChange, placeholder}) => {
    return (
        <Form.Control style={{color: "black", border: "2px solid black", borderWidth: "2px", background: "white", marginBottom: "10px"}}
                      required
                      as={as}
                      value={value}
                      type={type}
                      onChange={onChange}
                      placeholder={placeholder}
                      />
    )
}

export const PartnerFormControl = ({ as, type, value, onChange, placeholder}) => {
    return (
        <Form.Control style={{color: "black", border: "2px solid black", borderWidth: "2px", background: "white", marginBottom: "10px", fontSize: "1.5vw"}}
                      required
                      as={as}
                      value={value}
                      type={type}
                      onChange={onChange}
                      placeholder={placeholder}
                      />
    )
}

export const MobilePartnerFormControl = ({ as, type, value, onChange, placeholder}) => {
    return (
        <Form.Control style={{color: "black", border: "2px solid black", borderWidth: "2px", background: "white"}}
                      required
                      as={as}
                      value={value}
                      type={type}
                      onChange={onChange}
                      placeholder={placeholder}
                      className="form-control form-control-sm"
                      class="form-control form-control-sm"
                      />
    )
}

export const EventFormSelect = ({ value, name, onChange, data}) => {
    return (
        <Col style={{display: "flex", flexDirection: "column"}}>
            <Form.Select required
                         onChange={onChange}
                         value={value}
                         aria-label={"Please Select a " + name}
                         style={{border: "2px solid black",
                                 background: "white",
                                 color: "black",
                                 borderRadius: "5px",
                                 padding: "6px 12px 6px 12px"
                         }}>
                <option hidden value="">{name}</option>
                {data.map((data, index) => (
                    <option key={index}
                            value={data['name']}
                            style={{color: "black"}}>{data['name']}</option>
                ))}
            </Form.Select>
        </Col>
    );
}

export const InfoText = ({ text }) => {
    return <p style={{fontSize: "1rem"}}>{text}</p>
}

export const WhiteInfoText = ({ text }) => {
    return <p style={{fontSize: "1rem", color: "white"}}>{text}</p>
}

export const HeaderText = ({ text }) => {
    return <p style={{fontWeight: "normal", fontSize: "2rem"}}>{text}</p>
}

export const timeOffset = 4;

export function eventDateTimeFormatter(dateStart, dateEnd) {
    let finalTime = new Date(dateStart)
    let finalEndTime = new Date(dateEnd)

    finalTime = finalTime.setHours(finalTime.getHours() - timeOffset)
    finalEndTime = finalEndTime.setHours(finalEndTime.getHours() - timeOffset)

    finalTime = new Date(finalTime).toISOString()
    finalEndTime = new Date(finalEndTime).toISOString()

    const month = finalTime.slice(5, 7)
    const day = finalTime.slice(8, 10)
    const year = finalTime.slice(0, 4)


    let time = finalTime.slice(11, 16)
    if (parseInt(time.slice(0, 2)) >= 12) {
        if (parseInt(time.slice(0, 2)) === 12) {
            time = (parseInt(time.slice(0, 2))).toString() + time.slice(2) + "PM"
        } else {
            time = (parseInt(time.slice(0, 2)) - 12).toString() + time.slice(2) + "PM"
        }
    } else {
        time = time + "AM"
    }

    let endTime = finalEndTime.slice(11, 16)
    if (parseInt(endTime.slice(0, 2)) >= 12) {
        if (parseInt(endTime.slice(0, 2)) === 12) {
            endTime = (parseInt(endTime.slice(0, 2))).toString() + endTime.slice(2) + "PM"
        } else {
            endTime = (parseInt(endTime.slice(0, 2)) - 12).toString() + endTime.slice(2) + "PM"
        }
    } else {
        endTime = endTime + "AM"
    }

    return `${month}/${day}/${year}: ${time} - ${endTime}`;
}

export const FirstViewTypeWriterEffect = () => {
    // TODO: MAKE CURSOR DISAPPEAR, AND MAKE FADE OUTS LAST LONGER BEFORE DISAPPEARING

    return (
        <ReactTyped
            typeSpeed={40}
            strings={[
                "Championship Weekends",
                "4th Quarter Touchdowns",
                "Sprint Finishes",
                "Penalty Shootouts",
                "9th Inning Walkoffs",
                "Collegiate Sports",
                "The Fan Experience",
            ]}
            fadeOut
            fadeOutDelay={100}
            startDelay={1000}
            loop={false}
            cursorChar={'|'}
            onComplete={() => {
                // suck my fucking ballllllssssssss it works dickheads HAHA
                document.getElementsByClassName('typed-cursor')[0].style.opacity = 0
                // if we want a comma(la) to appear in its place, this is how we would do it
                // document.getElementById('appearing-comma').style.opacity = 1
            }}
        />
    );
};

export const BackToTopButton = () => {
    return (
        <button id='topbutt' onClick={() => {
            const el = document.getElementById("theTop")
            const els = document.getElementsByClassName("scroll-section")

            for (let i; i < els.length; i++) {
                els.style.scrollSnapAlign = 'none';
            }
            el.scrollIntoView({behavior: "smooth", block: "start"})
            for (let i; i < els.length; i++) {
                els.style.scrollSnapAlign = 'start';
            }
        }}><KeyboardDoubleArrowUpIcon /></button>
    )
}

export const DownloadInfo = () => {
    return (
        <Col style={{textAlign: "center", marginTop: "4vh", marginBottom: "8vh"}}>
            {/* <p style={{color: "white", fontSize: "1.5vh", marginLeft: "auto", marginRight: "auto"}}>Start Earning Today!</p> */}
            <div class="row justify-content-center">
                <a target="_blank" href="https://apps.apple.com/us/app/fanstand/id6475651512?itsct=apps_box_link&itscg=30200">
                <Image alt='Link to download the app on the App Store' style={{width: "20vw"}} src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/downloadOnAppStore.svg"/>
                </a>
            </div>

            <div class="row justify-content-center">
                <Image alt='QR code to download the app' style={{width: "15vw"}} src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/downloadCode.jpeg"/>
            </div>
        </Col>
    )
}
export const MobileDownloadInfo = () => {
    return (
        <Col style={{textAlign: "center", marginTop: "10vh"}}>
            {/* <p style={{color: "white", fontSize: "1.5vh", marginLeft: "auto", marginRight: "auto"}}>Start Earning Today!</p> */}
            <div class="row justify-content-center">
                <a target="_blank" href="https://apps.apple.com/us/app/fanstand/id6475651512?itsct=apps_box_link&itscg=30200">
                <Image alt='Link to download the app on the App Store' style={{width: "60vw"}} src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/downloadOnAppStore.svg"/>
                </a>
            </div>

            <div class="row justify-content-center">
                {/* nice */}
                <Image alt='QR code to download the app' style={{width: "69vw"}} src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/downloadCode.jpeg"/>
            </div>
        </Col>
    )
}

const TextBanner = ({ text }) => {
    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 5
            }}
        />
    );

    return (
        <Row style={{textAlign: "center", }}>
            <Col xs={4}>
                <ColoredLine color='lightgreen' />
            </Col>
            <Col xs={4} style={{marginTop: "-1.5vw"}}>
                <span style={{width: "100%", overflow: "hidden", color: "lightgreen", fontSize: "5.5vh"}}>
                    {text}
                </span>
            </Col>
            <Col xs={4}>
                <ColoredLine color='lightgreen' />  
            </Col>
        </Row>
    )
}