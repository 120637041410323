import {SERVER_ADDRESS} from "../constants";
import toast from "react-hot-toast";

async function getPastEvents(token, loginCheck) {
    const endpoint = SERVER_ADDRESS + '/api/v1/event/events/past'
    const toastId = toast.loading("Attempting to load events...")

    return await fetch(endpoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token['accessToken']
        },
    }).then(data => data.json()).then(data => {
        toast.dismiss()
        if (!loginCheck) {
            toast.success("Past Events were retrieved successfully!", {id: toastId})
        }
        return data
    }).catch(data => {
        toast.dismiss()
        if (!loginCheck) {
            toast.error("Error occurred when retrieving events.", {id: toastId})
        }
        return ['error']
    })
}

export default getPastEvents;