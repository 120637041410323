// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; // Import global styles
import App from './App';
import Error from "./pages/registrationAndError/SplitError"

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false};
    }

    static getDerivedStateFromError = error => {
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        // console.log("CAUGHT", error)
    }

    render() {
        if (this.state.hasError) {
            return (
                <Error />
            )
        }
        return this.props.children
    }
}

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary>
            <App/>
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root')
);
