import {SERVER_ADDRESS} from "../constants";

async function createUser(credentials) {
    const endpoint = SERVER_ADDRESS + '/api/v1/register'

    return fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    }).then(data => data.json()).then(json => json)
}

export default createUser;