import {SERVER_ADDRESS} from "../constants";
import toast from "react-hot-toast";

async function uploadImage(file, token) {
    const formData = new FormData();
    formData.append('file', file);
    const endpoint = SERVER_ADDRESS + '/api/v1/images/upload'
    const toastId = toast.loading("Attempting to upload image...")

    if (file['size'] > 1000000) {
        toast.error("Image Size too large (> 1MB). \nPlease select a different image.", {id: toastId})
        return ""
    }
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token['accessToken']
        },
        body: formData
    }).then(data => data.text()).then(data => data)

    if (!response["status"]) {
        toast.dismiss()
        toast.success("Image successfully uploaded!", {id: toastId})
    } else {
        toast.dismiss()
        toast.error("Image upload failed.", {id: toastId})
    }

    return response
}

export default uploadImage;