import "./SampleNotification.css"
import {useEffect, useState} from "react";
const SampleNotification = (props) => {
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");

    useEffect(() => {
        if (props.title.length > 30) { // supposed max character for iphone title notifications
            setTitle(props.title.substring(0, 27) + "...")
        } else {
            setTitle(props.title)
        }

        if (props.body.length > 155) {
            setBody(props.body.substring(0, 152) + "...")
        } else {
            setBody(props.body)
        }
    }, [props.title, props.body]);

    return (
        <div className='iphone' >
            <img src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/iphone.png"
                 alt='screenshot of an iPhone'
                 className='sampleIphone'/>
            <div className='sampleNotification'>
                <img src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/favicon.png"
                     alt="Sample of an apple notification"
                     className="notification-icon"/>
                <span id='title' className='sampleNotificationText'>Fanstand</span>
                <br/>
                <span id='title' className='sampleNotificationText'>{title}</span>
                <br/>
                <span id='body' className='sampleNotificationText'>{body}</span>
            </div>
        </div>


    )
};

export default SampleNotification;