import Header from "../../shared/Header/MobileHeader";
import Footer from "../../shared/Footer/MobileFooter";
import "./NewMobileHome.css";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import EmojiEvents from '@mui/icons-material/EmojiEvents';
import React from "react";
import Handshake from "@mui/icons-material/Handshake"
import {useState} from "react";
import Form from 'react-bootstrap/Form'
import { MobilePartnerFormControl, AnimatedDownArrowSingleton, MobileSubmitButton, FirstViewTypeWriterEffect, MobileDownloadInfo} from "../../shared/formComponents";
import partnerInfo from "../../shared/hooks/partnerInfo";
import { Fade } from "react-awesome-reveal"
import { cardClasses } from "@mui/material";


const MobilePartnerForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [school, setSchool] = useState('');
    const [message, setMessage] = useState('');

    const handlePartner = async e => {
        e.preventDefault();
        // console.log(name, email, school, message)
        await partnerInfo({
            name: name,
            school: school,
            email: email,
            message: message,
        })
        setName('');
        setEmail('');
        setSchool('');
        setMessage('');
    }

    return (
        <div style={{width: "80%", marginLeft: "auto", marginRight: "auto", marginTop: "8vh"}}>
            <p className="paragraphText">Sound interesting? Enter your info or reach out at <a href={"mailto:info@fanstandapp.com"}>info@fanstandapp.com</a></p>
            <Form onSubmit={handlePartner}>
                <Row>
                    <Col lg='auto'>
                        <MobilePartnerFormControl value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            placeholder="Name"/>
                    </Col>
                </Row>

                <Row>
                    <Col lg='auto'>
                        <MobilePartnerFormControl value={email}
                                        placeholder='Email'
                                        onChange={(e) => setEmail(e.target.value)}/>
                    </Col>
                </Row>

                <Row>
                    <Col lg='auto'>
                        <MobilePartnerFormControl value={school}
                                        placeholder='School'
                                        onChange={(e) => setSchool(e.target.value)}/>
                    </Col> 
                </Row>

                <Row>
                    <Col lg='auto'>
                        <MobilePartnerFormControl value={message}
                                        placeholder='Message (optional)'
                                        required='false'
                                        onChange={(e) => setMessage(e.target.value)}/>
                    </Col>
                </Row> 
            <Row className="row justify-content-center" >
                <MobileSubmitButton text='Partner with Us!'/>     
            </Row> 
            </Form>
        </div>
    )
}

const FirstView = () => {
    return (
        <div className="mobile-scroll-section" id="theTop">
            <Header/>

            <Row>
                <img alt='Screenshot of the Fanstand app homescreen with a iphone border' className="mobileScreenshot" style={{maxHeight: '40vh', marginLeft: 'auto', marginTop: "4vh"}} src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/mapFrame.png" />
            </Row>

            <Row style={{paddingBottom: "4vh", textAlign: "center", marginTop: "4vh"}}>
                <span style={{color: 'white', fontSize: '8vw', textWrap: "nowrap", marginLeft: "auto", marginRight: "auto"}}>
                    Energizing 
                    <br></br> 
                    <FirstViewTypeWriterEffect />
                    <br></br> 
                    <span style={{fontStyle: "italic", color: "lightgreen"}}>Everywhere.</span>
                </span>
            </Row>

            <Row>
                <button className='mobileButton' id="mobileButt1" onClick={() => {
                    const el = document.getElementById("downloadInfo")
                    el.scrollIntoView({behavior: "smooth"})
                }
                }>&nbsp;&nbsp;&nbsp;&nbsp;Earn Rewards&nbsp;<EmojiEvents style={{fontSize: 'inherit'}}/></button>
                <button className='mobileButton' id="mobileButt2" onClick={() => {
                    const el = document.getElementById("partnerForm")
                    el.scrollIntoView({behavior: "smooth", aligntoTop: "true"})
                }
                }>Partner with Us&nbsp;<Handshake style={{fontSize: 'inherit'}}/>&nbsp;&nbsp;&nbsp;&nbsp;</button>
            </Row>

            <Row style={{marginTop: 'calc((10vh * 2.5) - 30vw)'}}>
                <AnimatedDownArrowSingleton />
            </Row>
        </div>
    )
}

const StudentSection1 = () => {
    return (
        <Fade>
            <div className="mobile-scroll-section">
            <Row className='mobileRow' xs={1} sm={1} md={1}>
                <span className="headerText">
                    Show Up.
                    <span style={{color: "lightgreen"}}> Get Rewarded.</span><br></br>
                </span>
                <span className="paragraphText">
                        Fanstand gives you points for attending collegiate sporting events!
                        You can redeem those points for <span style={{color: 'lightgreen'}}>free merch!</span>
                   </span>
                   
                {/* <img className="mobileScreenshot" src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/leaderboardScreenshots.jpeg" /> */}
                <img alt='Screenshot of the Fanstand app homescreen with a iphone border' className="mobileScreenshot" src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/mapFrame.png" />
            </Row>
            </div>
        </Fade>    
    )
}

const StudentSection2 = () => {
    return (
        <Fade>
            <div className="mobile-scroll-section">
            <Row className='mobileRow' xs={1} sm={1} md={1}>
                <span className="headerText">
                    <span style={{color: "lightgreen"}}>Find Events </span>
                    Near You.
                </span>
                <span className="paragraphText">
                        Fanstand makes it easy to see what's happening on campus, 
                        <span style={{color: 'lightgreen'}}> keeping you in the action.</span>
                   </span>
                {/* <img className="mobileScreenshot" src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/leaderboardScreenshots.jpeg" /> */}
                <img alt='Screenshot of the Fanstand app map view with a iphone border' className="mobileScreenshot" src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/fullEventFrame.png" />
            </Row>
            </div>
        </Fade>    
    )
}

const StudentSection3 = () => {
    return (
        <Fade>
            <div className="mobile-scroll-section">
            <Row className='mobileRow' xs={1} sm={1} md={1}>
                <span className="headerText">
                    Become the 
                    <span style={{color: "lightgreen"}}> Ultimate Fan.</span><br></br>
                </span>

                <span className="paragraphText">
                    Compete against your friends to earn the most points!
                   </span>
                   
                {/* <img className="mobileScreenshot" src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/leaderboardScreenshots.jpeg" /> */}
                <img className="mobileScreenshot" alt='Screenshot of the Fanstand app leaderboard with a iphone border' src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/leaderboardFrame.png" />
            </Row>
            </div>
        </Fade>    
    )
}

const StudentSection4 = () => {
    return (
        <Fade>
            <div className="mobile-scroll-section" id='downloadInfo'>
            <Row className='mobileRow' xs={1} sm={1} md={1}>
                <span className="headerText">
                    Get <span style={{color: "lightgreen"}}>Fanstand</span> Today!
                </span>
                <span className="paragraphText">
                    Support your peers and start earning rewards! <br></br>Scan or tap below to download.
                </span>
                <MobileDownloadInfo />
            </Row>

            <Row style={{marginTop: 'calc((10vh * 2.5) - 30vw)'}}>
                <AnimatedDownArrowSingleton />
            </Row>
            </div>
        </Fade>    
    )
}

const StudentSection = () => {
    return (
        <>
            <section  id='studentInfo'></section>
            <StudentSection1 />  
            <StudentSection2 />  
            <StudentSection3 />  
            <StudentSection4 />  
        </>
    )
}

const PartnerSection1 = () => {
    return (
        <div className="mobile-scroll-section">
        <Fade>
            <Row className='mobileRow' xs={1} sm={1} md={1}>
                <Col>
                    <span className='headerText'>Building Community<br></br></span>
                    
                    <span className='paragraphText'>
                    Fanstand encourages community engagement through athletic events - building a <span style={{color: "lightgreen"}}>stronger campus community,</span>  and strengthening student relationships.
                    </span>

                    <img alt='College atheletes standing in a circle on a field' className='mobilePhoto' src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/peopleCircle.jpeg" />
                    <a style={{color: "white", fontSize: "1vh"}} target='_blank' href='https://spec.hamilton.edu/womens-soccer-and-field-hockey-fall-in-nescac-quarterfinal-matches-e7acdb6a8c3c'>
                    Credit: Josh McKee through The Spectator
                    </a>
                </Col>
            </Row>
        </Fade>
        </div>
    )
}

const PartnerSection2 = () => {
    return (
        <div className="mobile-scroll-section">
        <Fade>
            <Row className='mobileRow' xs={1} sm={1} md={1}>
                <Col>
                {/* https://spec.hamilton.edu/womens-soccer-and-field-hockey-fall-in-nescac-quarterfinal-matches-e7acdb6a8c3c */}
                    <span className='headerText'>What We Offer</span>
                        <br></br>
                    <span className='paragraphText' style={{textAlign: "start"}}>
                        <ul>
                            <li><span style={{color: "lightgreen"}}>Cutting edge analytics:</span> Marketing tailored to each student. Understand your attendance with Fanstand.</li>
                            <li><span style={{color: "lightgreen"}}>Robust event manager:</span> Intuitive event and reward management. Add a new event in seconds.</li>
                            <li><span style={{color: "lightgreen"}}>Notifications:</span> Live updates for students on everything from upcoming events to the game-winning goal.</li>
                        </ul>
                        </span>
                    <img className='mobilePhoto'
                         alt='Aerial photo of a rural college in the fall'
                         src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/aerialCampus.jpeg"/>
                    <a style={{color: "white", fontSize: "1vh"}} target='_blank'
                       href='https://spec.hamilton.edu/upcoming-hamtrek-anticipates-record-turnout-c15984ab45f'>
                        Credit: Hamilton College through The Spectator
                    </a>
                </Col>
            </Row>
        </Fade>
        </div>
    )
}

const PartnerSection3 = () => {
    return (
        <div className="mobile-scroll-section" id='partnerForm'>
            <Fade>
            <Row className='mobileRow'>
                {/* https://spec.hamilton.edu/womens-soccer-and-field-hockey-fall-in-nescac-quarterfinal-matches-e7acdb6a8c3c */}
                    <span className='headerText'>Partner with Us!</span>
                        <br></br>

                    <span className="paragraphText">
                        We want to help you grow engagement in athletics, better your campus community, and enhance the college experience for your students.
                    </span>

                <MobilePartnerForm />
                
            </Row>

            <Row style={{marginTop: 'max(calc((15vh * 3) - 80vw), 8vh)'}}>
                <Col>
                    <Row  className='justify-content-center'>
                        <Footer/>
                    </Row>
                </Col>
            </Row>
        </Fade>
        </div>
    )
}

const PartnerSection = () => {
    return (
        <div style={{marginTop: "10vw"}}>        
            <PartnerSection1 />
            <PartnerSection2 />
            <PartnerSection3 />
            
        </div>
    )
}


// const UniversitySection = () => {
//     return (
//         <>
//             <div className='mobile-scroll-section'>
//                 <Row className="mobileRow">
//                     <span className="headerText"> Creating Community Through Athletics</span>
//                     <span className="paragraphText">
//                         Fanstand encourages community engagement through athletic events - <br></br>
//                         <span style={{color: "lightgreen"}}> creating positive campus interactions and strengthing interstudent relationships.</span>
//                     </span>
//                     <img src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/peopleCircle.jpeg" className='mobileImage'/>
//                         <a style={{color: "white", fontSize: "1vh",  marginLeft: "auto", marginRight: "auto"}} target='_blank' href='https://spec.hamilton.edu/womens-soccer-and-field-hockey-fall-in-nescac-quarterfinal-matches-e7acdb6a8c3c'>
//                         Credit: Josh McKee through The Spectator
//                         </a>
//                 </Row>
//                 {/* <AnimatedDownArrow /> */}

//             </div>
//             <div className="mobile-scroll-section" id='partnerForm' >
//                 <Row className="mobileRow">
//                     <span className="headerText">One degree of Separation</span>
//                     <span className="paragraphText">
//                         Everyone knows an athlete! Almost <span className="paragraphText" style={{color: 'lightgreen'}}>1 in 3</span> students at D3 colleges are athletes. Athletics, and the athletes who participate, 
//                         present a <span className="paragraphText" style={{color: 'lightgreen'}}>unique opportunity</span> to build inter-group community at colleges everywhere.
//                     </span>
//                     <img src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/aerialCampus.jpeg" className='mobileImage'/>
//                         <a style={{color: "white", fontSize: "1vh",  marginLeft: "auto", marginRight: "auto"}} target='_blank' href='https://spec.hamilton.edu/upcoming-hamtrek-anticipates-record-turnout-c15984ab45f'>
//                         Credit: Hamilton College through The Spectator
//                         </a>
//                 </Row>

//                 <Row className="mobileRow" style={{paddingTop: "5vh",}}>
//                     <Col><MobilePartnerForm /></Col>
//                 </Row>
//             </div>
//         </>
//     )
// }

const MobileHome = () => {
    return (
        <div id='container' style={{scrollPaddingBlock: "0"}}>
            <div style={{height: "100vh", backgroundColor: "black", paddingTop: "25vh"}}>
                <Container style={{maxWidth: "100%", margin: "0"}}>
                    <FirstView />
                    <StudentSection />
                    <PartnerSection />
                </Container>
            </div>
        </div>
    );
};

export default MobileHome;