import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Analytics from "./pages/analytics/Analytics.js";
import EventManager from "./pages/eventManager/EventManager.js";
import {ProtectedRoute} from "./pages/login/ProtectedRoute";
import Login from "./pages/login/Login";
import Notifications from "./pages/notifications/Notifications";
import RegistrationConfirm from "./pages/registrationAndError/RegistrationConfirm.js";
import RegistrationError from "./pages/registrationAndError/RegistrationError.js";
import RegistrationSuccess from "./pages/registrationAndError/RegistrationSuccess.js";
import {Toaster} from "react-hot-toast";
import Privacy from "./pages/policy/Privacy";
import Terms from "./pages/policy/Terms";
import AcceptableUse from "./pages/policy/AcceptableUse";
import Error from "./pages/registrationAndError/Error.js"
import React, {useState} from 'react';
import NewHome from './pages/newhome/NewHome.js';
import About from './pages/about/About.js';
import PastEvents from './pages/pastEvents/pastEvents.js';

const DesktopApp = () => {
    const [token, setToken ] = useState("");
    return (
        <BrowserRouter>
            <Toaster/>
            <Routes>
                <Route path='/' element={<NewHome />} />
                <Route path='/about' element={<About />} />
                {/* <Route path='/registrationConfirm' element={<RegistrationConfirm />} />
                <Route path='/registrationError' element={<RegistrationError />} />
                <Route path='/registrationSuccess' element={<RegistrationSuccess />} /> */}
                <Route path='/privacy' element={<Privacy />} />
                <Route path='/terms' element={<Terms />} />
                <Route path='/acceptable' element={<AcceptableUse />} />
                <Route path='/login' element={<Login setToken={setToken}/>} />
                <Route path="*" element={<Error />} />
                <Route
                    path="/admin"
                    element={
                        <ProtectedRoute token={token} setToken={setToken}>
                            <EventManager token={token}/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/admin/past"
                    element={
                        <ProtectedRoute token={token} setToken={setToken}>
                            <PastEvents token={token}/>
                        </ProtectedRoute>
                    }
                />          
                {/* <Route
                    path="/admin/analytics"
                    element={
                        <ProtectedRoute token={token} setToken={setToken}>
                            <Analytics token={token}/>
                        </ProtectedRoute>
                    }
                /> */}
                <Route
                    path="/admin/notifications"
                    element={
                        <ProtectedRoute token={token} setToken={setToken}>
                            <Notifications token={token}/>
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}

export default DesktopApp;