import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import "./EventManagerSwitchFormButton.css"
import EventInformationModal from "../pages/eventManager/EventInformationModal";
import InfoIcon from '@mui/icons-material/Info';
const EventManagerSwitchFormButtons = (props) => {
    const [show, setShow] = useState(false)

    return (
        <>
            {props.userEvent &&
                <div style={{padding: "10%", paddingBottom: "1%"}}>
                    <Button id='active' className='eventManagerSwitchFormButton' onClick={() => {props.setUserEvent(true); props.clear();}}>
                        <span className='active'>Sports Event&nbsp;</span>
                        <InfoIcon
                            onClick={() => setShow(true)}
                            sx={{
                                '&': {
                                    color: 'black',
                                    backgroundColor: 'white',
                                    transition: 'none'
                                },
                            }}
                        />
                    </Button>

                    <Button id='inactive' className='eventManagerSwitchFormButton' onClick={() => {props.setUserEvent(false); props.clear();}}>
                        <span className='deactive'>Redeem Event</span>
                    </Button>
                    <EventInformationModal show={show} setShow={setShow} user={true}/>
                </div>
            }

            {!props.userEvent &&
                <div style={{padding: "10%", paddingBottom: "1%"}}>
                    <Button id='inactive' className='eventManagerSwitchFormButton' onClick={() => {props.setUserEvent(true); props.clear();}}>
                        <span className='deactive'>Sports Event</span>
                    </Button>

                    <Button id='active' className='eventManagerSwitchFormButton' onClick={() => {props.setUserEvent(false); props.clear();}}>
                        <span className='active'>Redeem Event&nbsp;</span>
                        <InfoIcon
                            onClick={() => setShow(true)}
                            sx={{
                                '&': {
                                    color: 'black',
                                    backgroundColor: 'white',
                                    transition: 'none'
                                },
                            }}
                        />
                    </Button>
                    <EventInformationModal show={show} setShow={setShow} user={false}/>
                </div>
            }
        </>
    );
};

export default EventManagerSwitchFormButtons;