import { SERVER_ADDRESS } from "../constants";
import toast from "react-hot-toast";

async function partnerInfo(credentials) {
    const endpoint = SERVER_ADDRESS + '/api/v1/website/contactForm'
    const toastId = toast.loading("Sending contact info...")
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials)
    })

    if (response.status === 200) {
        toast.dismiss()
        toast.success("Message sent! We will be in touch soon!", {id: toastId})
    } else {
        toast.dismiss()
        toast.error("Couldn't send the message. Try again or try email.", {id: toastId})
    }

    return response
}

export default partnerInfo;