import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MobileRegistrationConfirm from "./pages/registrationAndError/MobileRegistrationConfirm.js";
import MobileRegistrationError from "./pages/registrationAndError/MobileRegistrationError.js";
import MobileRegistrationSuccess from "./pages/registrationAndError/MobileRegistrationSuccess.js";
import {Toaster} from "react-hot-toast";
import MobilePrivacy from "./pages/policy/MobilePrivacy";
import MobileTerms from "./pages/policy/MobileTerms";
import MobileAcceptableUse from "./pages/policy/MobileAcceptableUse";
import MobileError from "./pages/registrationAndError/MobileError.js"
import MobileAbout from './pages/about/MobileAbout.js';
import NewMobileHome from "./pages/professionalHome/NewMobileHome.js"

const MobileApp = () => {
    return (
        <BrowserRouter>
            <Toaster/>
            <Routes>
                <Route path='/' element={<NewMobileHome />} />
                <Route path='/about' element={<MobileAbout />} />
                {/* <Route path='/registrationConfirm' element={<MobileRegistrationConfirm />} />
                <Route path='/registrationError' element={<MobileRegistrationError />} />
                <Route path='/registrationSuccess' element={<MobileRegistrationSuccess />} /> */}
                <Route path="*" element={<MobileError />} />
                <Route path='/privacy' element={<MobilePrivacy />} />
                <Route path='/terms' element={<MobileTerms />} />
                <Route path='/acceptable' element={<MobileAcceptableUse />} />
            </Routes>
        </BrowserRouter>
    );
}

export default MobileApp;