import {SERVER_ADDRESS} from "../constants";
import toast from "react-hot-toast";

async function addNotification(credentials, token) {
    const endpoint = SERVER_ADDRESS + '/api/v1/notification'
    const toastId = toast.loading("Attempting to send notification...")
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token['accessToken']
        },
        body: JSON.stringify(credentials)
    }).then(data => data)

    if (response.status === 200) {
        toast.dismiss()
        toast.success("Notification was sent successfully!", {id: toastId})
    } else {
        toast.dismiss()
        toast.error("Error occurred when sending notification.", {id: toastId})
    }
}

export default addNotification;