import React, {useState, useEffect} from 'react';
import MobileError from './MobileError';
import DesktopError from './Error';

export const SplitError = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [mobileDevice, setMobileDevice] = useState(false)

    function handleWindowSizeChange() {
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
        const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);
        setMobileDevice(isMobile || isTablet);
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (isMobile) ? <MobileError /> : <DesktopError />;
}

export default SplitError;