import {Circle, MapContainer, Marker, TileLayer, useMap, useMapEvent} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import "./EventManagerMap.css"
import L from 'leaflet'
import {useEffect, useRef} from "react";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const DisplayEventLocationMap = (props) => {
    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [props.update, props.lat, props.lng]);

    function MarkerComponent() {
        return (
            <>
                <Marker position={[props.lat, props.lng]}></Marker>
                <Circle center={[props.lat, props.lng]} radius={props.radius} pathOptions={{fillColor: 'red'}}></Circle>
            </>
        )
    }

    function MoveMap() {
        const map = useMap();
        map.setView([props.lat, props.lng], map.getZoom());

        return null;
    }

    const api_key = "37298181-cc93-4ddd-826c-306f3959c265"

    return (
        <>
            <MapContainer center={[props.lat, props.lng]} zoom={16}
                          scrollWheelZoom={false} dragging={false} doubleClickZoom={false} zoomControl={false}
                            zoomSnap={false} zoomDelta={false} touchZoom={false}>

                <TileLayer
                    attribution='&copy; <a href="https://stadiamaps.com/" target="_blank">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>'
                    // url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png?api_key=37298181-cc93-4ddd-826c-306f3959c265"
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <MarkerComponent/>
                <MoveMap />
            </MapContainer>
        </>
    )


};

export default DisplayEventLocationMap;
