import {SERVER_ADDRESS} from "../constants";

// UNUSED IN PRODUCTION AS OF VERSION 1

async function getEventInformation(eventId, token) {
    const endpoint = SERVER_ADDRESS + '/api/v1/event/eventInformation/' + eventId

    return fetch(endpoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token['accessToken']
        },
    }).then(data => data.json()).then(json => json)
}
export default getEventInformation;