import {SERVER_ADDRESS} from "../constants";
import toast from "react-hot-toast";

async function deleteEvent(eventId, token) {
    const endpoint = SERVER_ADDRESS + '/api/v1/event'
    const toastId = toast.loading("Attempting to delete event...")
    const response = await fetch(endpoint, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token['accessToken']
        },
        body: JSON.stringify(eventId)
    }).then(data => data)

    if (response.status === 200) {
        toast.dismiss()
        toast.success("Event was deleted successfully!", {id: toastId})
    } else {
        toast.dismiss()
        toast.error("Error occurred when deleting event.", {id: toastId})
    }
}

export default deleteEvent;