// src/components/Footer/Footer.js
import React from 'react';
import './Footer.css';
import {Link} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Instagram from '@mui/icons-material/Instagram';
import LinkedIn from "@mui/icons-material/LinkedIn";
import TikTok from "@mui/icons-material/MusicNote";
import Image from 'react-bootstrap/Image'


const MobileFooter = () => {
    return (
        <div style={{textAlign: "center", marginTop: "5vh"}}>
            {/* <Image src="https://fanstandblobs.blob.core.windows.net/images/fanstandLogoRounded.png" style={{width: "8vh"}} /> */}
                <p style={{color: "white"}}>© 2024 201 Interactive LLC</p>

            <a href="https://www.linkedin.com/company/fanstandapp/people/" target="_blank"
            rel="noopener noreferrer">
                <LinkedIn className="footerAppIcon"/>
                Linkedin
            </a>

            <br></br>

            <a href="https://www.instagram.com/fanstand.app?igsh=bnNrcGxzNGdjeXNj&utm_source=qr" target="_blank"
            rel="noopener noreferrer">
                <Instagram className="footerAppIcon"/>
                Instagram
            </a>

            <br></br>

            <a href="https://www.tiktok.com/@fanstandapp?_t=8lAScCLAsYM&_r=1" target="_blank"
            rel="noopener noreferrer">
                <TikTok className="footerAppIcon"/>
                TikTok
            </a>

            <br></br>

            <Link to="/privacy">
                Privacy Policy
            </Link>

            <br></br>

            <Link to="/acceptable">
                Acceptable Use Policy
            </Link>

            <br></br>
    
            <Link to="/terms">
                Terms of Service
            </Link>
        </div>
    );
};

export default MobileFooter;
