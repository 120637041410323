import HeaderAdmin from "../../shared/Header/HeaderAdmin.js";
import Footer from "../../shared/Footer/Footer";
import "./Notifications.css"
import Form from "react-bootstrap/Form";
import {useState} from "react";
import addNotification from "../../shared/hooks/addNotification";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import SampleNotification from "./SampleNotification";
import Modal from "react-bootstrap/Modal";
import {EventFormControl, HeaderText, InfoText, SubmitButton} from "../../shared/formComponents";

const Notifications = ({ token }) => {
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [modalShow, setModalShow] = useState(false);

    function lengthControl(string, isTitle) {
        if (isTitle) {
            if (string.length > 30) {
                return string.substring(0, 27) + "..."
            } else {
                return string
            }
        } else {
            if (string.length > 155) {
                return string.substring(0, 155) + "..."
            } else {
                return string
            }
        }
    }

    const handleNotificationSend = async e => {
        e.preventDefault();
        await addNotification({
            title: title,
            body: body
        }, token);
        setModalShow(false);
        setTitle("");
        setBody("");
    };

    const NotificationConfirmModal = ({ show, title, body }) => {
        return (
            <Modal
                show={show}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Confirm Notification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Please ensure this is the notification you would like to send:<br/>
                    Title: <span style={{fontWeight: "bold"}}>{title}</span><br/>
                    Body: {body}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setModalShow(false)}>
                        Make Changes
                    </Button>
                    <Button variant='success' onClick={handleNotificationSend}>Send Notification</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <div className="app">
            <HeaderAdmin/>
            <Row style={{margin: 0}}>
                <Col sm={8}>
                <div className="sendNotification">
                    <HeaderText text='Notification Manager' />
                    <InfoText text="This page allows you to send notifications to all users with the Fanstand app installed. Please use this service responsibly."/>
                    <InfoText text="The title can display 30 characters before it truncates. The body can display 155. A sample notification can be seen on the right." />
                    <Form onSubmit={(e) => {e.preventDefault(); setModalShow(true);}}>
                        <EventFormControl value={title}
                                          placeholder="Title"
                                          onChange={(e) => setTitle(e.target.value)}
                        />

                        <EventFormControl value={body}
                                          placeholder="Body"
                                          as='textarea'
                                                  onChange={(e) => setBody(e.target.value)}
                        />

                        <SubmitButton text='Send Notification' />

                    </Form>
                </div>
                </Col>
                <Col sm={4}>
                    <SampleNotification
                    title={title}
                    body={body}/>
                </Col>
            </Row>
            <NotificationConfirmModal
                show={modalShow}
                setShow={setModalShow}
                title={lengthControl(title, true)}
                body={lengthControl(body, false)}
                sendNotif={handleNotificationSend}
            />
            <Footer/>
        </div>
    );
};

export default Notifications;