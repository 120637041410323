import "./LiveEvents.css"
import {useEffect, useState} from "react";
import { Row, Col } from "react-bootstrap"
import ListGroup from 'react-bootstrap/ListGroup';


const LiveEvents = ({ activeEvents }) => {
    return (
        <Col>
            <Row className='liveEventRow'>
                Live Event Viewer
            </Row>

            <Row className='liveEventViewerRow'>
                <ListGroup>
                    {activeEvents.map((data, index) => {
                        return <ListGroup.Item id={index} className='liveEventViewItem'>{data['title']} at {data['location']}</ListGroup.Item>
                    })}
                </ListGroup>
            </Row>


        </Col>
    )
};

export default LiveEvents;
