import Header from "../../shared/Header/Header";
import Footer from "../../shared/Footer/Footer";
import "./NewHome.css";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import EmojiEvents from '@mui/icons-material/EmojiEvents';
import Handshake from "@mui/icons-material/Handshake"
import {React, useState} from "react";
import Form from 'react-bootstrap/Form'
import {
    AnimatedDownArrowSingleton,
    BackToTopButton,
    DownloadInfo,
    FirstViewTypeWriterEffect,
    PartnerFormControl,
    SubmitButton
} from "../../shared/formComponents";

import {Fade} from "react-awesome-reveal"
import partnerInfo from "../../shared/hooks/partnerInfo";

// import EventView from "../../shared/Photos/Event_View-portrait.png"
// import LeaderboardView from "../../shared/Photos/Leaderboard-portrait.png"
// import Homescreen from "../../shared/Photos/Homescreen-portrait.png"

// COMBO SCRIPT TO RUN TO SEE ELEMENTS THAT OVERFLOW HORIZONTALLY
// var jq = document.createElement('script');
// jq.src = "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js";
// document.getElementsByTagName('head')[0].appendChild(jq);
// // ... give time for script to load, then type (or see below for non wait option)
// jQuery.noConflict();

// $.each( $('*'), function() { 
//     if( $(this).width() > $('body').width()) {
//         console.log("Wide Element: ", $(this), "Width: ", $(this).width()); 
//     } 
// });


const PartnerForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [school, setSchool] = useState('');
    const [message, setMessage] = useState('');

    const handlePartner = async e => {
        e.preventDefault();
        // console.log(name, email, school, message)
        await partnerInfo({
            name: name,
            email: email,
            school: school,
            message: message,
        })
        setName('');
        setEmail('');
        setSchool('');
        setMessage('');
    }

    return (
        <div style={{marginBottom: "2vw", marginTop: "10vh"}}>
            <p style={{color: "white", fontSize: "1.5vw"}}>Sound interesting? Enter your info or reach out at <a href={"mailto:info@fanstandapp.com"}>info@fanstandapp.com</a></p>
            <Form onSubmit={handlePartner}>
            {/* <h1 style={{fontSize: "1rem", color: "white"}}></h1> */}
                <Row>
                    <Col xs={6}>
                        <PartnerFormControl value={name} style={{width: "10vw"}}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Name" />
                    </Col>

                    <Col xs={6}>
                        <PartnerFormControl value={school}
                                        placeholder='School'
                                        onChange={(e) => setSchool(e.target.value)}/>
                    </Col> 
                </Row>
                <Row>
                    <Col xs={12}>
                        <PartnerFormControl value={email}
                                        placeholder='Email'
                                        onChange={(e) => setEmail(e.target.value)}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <PartnerFormControl value={message}
                                        placeholder='Message (optional)'
                                        required='false'
                                        onChange={(e) => setMessage(e.target.value)}/>
                    </Col>
                </Row> 
            <Row>
                <Col ></Col>
                <Col xs='auto' style={{alignItems: 'center'}}><SubmitButton text='Partner with Us!'/></Col>     
                <Col ></Col>    
            </Row> 
            </Form>
        </div>
    )
}

const FirstView = () => {
    return (
        <div style={{height: "100vh", marginBottom: "10vh"}} className='scroll-section' id="theTop">
            <Row style={{paddingTop: "max(calc((3000px - 100vw) / 20), 10vh)", paddingBottom: "5vh"}}>
                <Col xs={12}>
                    <span style={{color: 'white', fontSize: '6.5vw', textWrap: "nowrap"}}>
                        Energizing 
                        <br></br> 
                        <FirstViewTypeWriterEffect />
                        <br></br> 
                        <span style={{fontStyle: "italic", color: "lightgreen"}}>Everywhere.</span>
                    </span>
                </Col>
            </Row>
            <Row style={{alignContent:"left", paddingBottom: "max(calc((1500px - 100vw) / 5), 5vh)"}}>
                <button id='butt1' onClick={() => {
                    const el = document.getElementById("downloadInfo")
                    const els = document.getElementsByClassName("scroll-section")
                    // Joe if you see any of this I am so sorry. I did NOT want to have to change
                    // css values for all items of a particular class, but it was the only way
                    // to have strict scroll and buttons that scrolled. I hope you forgive me 
                    for (let i; i < els.length; i++) {
                        els.style.scrollSnapAlign = 'none';
                    }
                    el.scrollIntoView({behavior: "smooth", block: "start"})
                    for (let i; i < els.length; i++) {
                        els.style.scrollSnapAlign = 'start';
                    }
                }
                }>&nbsp;&nbsp;&nbsp;&nbsp;Download the App&nbsp;<EmojiEvents style={{fontSize: "inherit"}}/>&nbsp;&nbsp;&nbsp;&nbsp;</button>
                
                <button id='butt2' onClick={() => {
                    const el = document.getElementById("partnerForm")
                    const els = document.getElementsByClassName("scroll-section")
                    for (let i; i < els.length; i++) {
                        els.style.scrollSnapAlign = 'none';
                    }
                    el.scrollIntoView({behavior: "smooth", block: "start"})
                    for (let i; i < els.length; i++) {
                        els.style.scrollSnapAlign = 'start';
                    }
                    // first time i ever used 'inherit' and it actually fucking worked lmao. gonna keep it bc its cool but i dont trust it
                }}>&nbsp;&nbsp;&nbsp;&nbsp;Partner with Us&nbsp;<Handshake style={{fontSize: "inherit"}}/>&nbsp;&nbsp;&nbsp;&nbsp;</button>
            </Row>
            <Row style={{marginTop: "5vh"}}>
                <AnimatedDownArrowSingleton />
            </Row>
        </div>
    )
}

const StudentSection1 = () => {
    return (
        <Fade>
            <Row style={{marginBottom: "20vh"}}className="scroll-section">
                <Col xs={6} style={{marginTop: "10vh", textAlign: "center"}}>

                <span style={{fontSize: "4.5vw", color: "white"}}>
                    Show Up. <br></br> 
                    <span style={{color: "lightgreen"}}>Get Rewarded.</span><br></br>
                </span>
                <span style={{fontSize: "2vw", color: "white"}}>
                        Fanstand gives you points for attending collegiate sporting events!
                        <br></br>
                        You can redeem those points for <span style={{color: 'lightgreen'}}>free merch!</span>
                   </span>
                </Col>

                <Col xs={6} style={{textAlign: "center"}}>
                    <img alt='Screenshot of the Fanstand app homescreen with a iphone border' className='desktopScreenshot' src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/mapFrame.png" />
                </Col>
            </Row>
        </Fade>    
    )
}

const StudentSection2 = () => {
    return (
        <Fade>
            <Row style={{marginBottom: "20vh"}} className="scroll-section">
                <Col xs={6} style={{textAlign: 'center',}}>
                
                    {/* <img style={{height: "80vh"}} src={Homescreen} /> */}
                    <img alt='Screenshot of the Fanstand app map view with a iphone border' className='desktopScreenshot' src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/fullEventFrame.png" />
                </Col>

                <Col xs={6} style={{marginTop: "10vh", textAlign: "center"}}>
                    <span style={{fontSize: "4.5vw", color: "white"}}>
                        <span style={{color: "lightgreen"}}>Find Events</span><br></br>Near You.
                        </span>
                        <br></br>
                    <span style={{fontSize: "2vw", color: 'white'}}>
                    Fanstand makes it easy to see what's happening on campus, 
                    <span style={{color: 'lightgreen'}}><br></br>keeping you in the action.</span>
                    </span>
                </Col>

            </Row>
        </Fade>    
    )
}

const StudentSection3 = () => {
    return (
        <Fade>
            <Row style={{marginBottom: "20vh"}} className="scroll-section">
                <Col xs={6} style={{marginTop: "10vh", textAlign: "center"}}>

                <span style={{fontSize: "4.5vw", color: "white"}}>
                    Become the <br></br> 
                    <span style={{color: "lightgreen"}}>Ultimate Fan.</span><br></br>
                </span>
                <span style={{fontSize: "2vw", color: "white"}}>
                        Compete against your friends to earn the most points. Medalists receive exclusive prizes each season!
                   </span>
                </Col>

                <Col xs={6} style={{textAlign: "center"}}>
                    {/* <img style={{height: "80vh"}} src={LeaderboardView} /> */}
                    <img alt='Screenshot of the Fanstand app leaderboard with a iphone border' className='desktopScreenshot' src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/leaderboardFrame.png" />
                </Col>
            </Row>
        </Fade>    
    )
}

const StudentSection4 = () => {
    return (
        <Fade>
            <Row style={{marginTop: "8vh"}} id='downloadInfo' className="scroll-section">
                <Col style={{textAlign: "center"}}>
                    <span style={{fontSize: "4.5vw", color: "white"}}>
                        Get <span style={{color: "lightgreen"}}>Fanstand</span> Today!
                        </span>
                        <br></br>
                    <span style={{fontSize: "2vw", color: 'white'}}>
                    Support your peers and start earning rewards! <br></br>Scan or tap below to download.
                    </span>
                    <DownloadInfo />
                    <AnimatedDownArrowSingleton />
                </Col>

            </Row>
        </Fade>    
    )
}

const StudentSection = () => {
    return (
        <>
            <section  id='studentInfo' ></section>
            <StudentSection1 />  
            <StudentSection2 />  
            <StudentSection3 />  
            <StudentSection4 />  
        </>
    )
}

const PartnerSection1 = () => {
    return (
        <div className="scroll-section">
        <Fade>
            <Row style={{marginBottom: "20vh", paddingTop: "2%", textAlign: 'center'}} md={1} lg={2} xl={2} xxl={2}>
                <Col>
                {/* https://spec.hamilton.edu/womens-soccer-and-field-hockey-fall-in-nescac-quarterfinal-matches-e7acdb6a8c3c */}
                    <span style={{fontSize: "4.5vw", color: "white"}}>Building Community</span>
                        <br></br>
                    <span style={{fontSize: "2vw", color: "white"}}>
                        Fanstand encourages engagement through athletic events- building a </span>
                        <span style={{fontSize: "2vw", color: "lightgreen"}}>stronger campus community, </span>
                    <span style={{fontSize: "2vw", color: "white"}}>and strengthening student relationships.</span>
                </Col>
                
                <Col style={{textAlign: 'center'}}>
                    <img alt='College atheletes standing in a circle on a field' style={{height: "65vh", display: "block", marginLeft: 'auto', marginRight: 'auto', maxWidth: "100%"}}src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/peopleCircle.jpeg" />
                    <a style={{color: "white", fontSize: "1vh"}} target='_blank' href='https://spec.hamilton.edu/womens-soccer-and-field-hockey-fall-in-nescac-quarterfinal-matches-e7acdb6a8c3c'>
                    Credit: Josh McKee through The Spectator
                    </a>
                </Col>
            </Row>
        </Fade>
        </div>
    )
}

const PartnerSection2 = () => {
    return (
        <div className="scroll-section">
        <Fade>
            <Row style={{marginBottom: "20vh", paddingTop: "2%", textAlign: 'center'}} md={1} lg={2} xl={2} xxl={2}>
                <Col md={{order: '2'}} lg={{order: '1'}} xl={{order: '1'}} xxl={{order: '1'}}>
                    <img alt='Aerial photo of a rural college in the fall' style={{height: "65vh", display: "block", marginLeft: 'auto', marginRight: 'auto', maxWidth: "100%"}}src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/aerialCampus.jpeg" />
                    <a style={{color: "white", fontSize: "1vh"}} target='_blank' href='https://spec.hamilton.edu/upcoming-hamtrek-anticipates-record-turnout-c15984ab45f'>
                    Credit: Hamilton College through The Spectator
                    </a>
                </Col>

                <Col md={{order: '1'}} lg={{order: '2'}} xl={{order: '2'}} xxl={{order: '2'}}>
                {/* https://spec.hamilton.edu/womens-soccer-and-field-hockey-fall-in-nescac-quarterfinal-matches-e7acdb6a8c3c */}
                    <span style={{fontSize: "4.5vw", color: "white"}}>What We Offer</span>
                        <br></br>
                    <span style={{fontSize: "2vw", color: "white", textAlign: "start"}}>
                        <ul>
                            <li><span style={{fontSize: "2vw", color: "lightgreen"}}>Cutting edge analytics:</span> Marketing tailored to each student. Understand your attendance with Fanstand.</li>
                            <li><span style={{fontSize: "2vw", color: "lightgreen"}}>Robust event manager:</span> Intuitive event and reward management. Add a new event in seconds.</li>
                            <li><span style={{fontSize: "2vw", color: "lightgreen"}}>Notifications:</span> Live updates for students on everything from upcoming events to the game-winning goal.</li>
                        </ul>
                    </span>
                </Col>
            </Row>
        </Fade>
        </div>
    )
}

const PartnerSection3 = () => {
    return (
        <div className="scroll-section" id='partnerForm'>
        <Fade>
            <Row style={{marginTop: "10vw", textAlign: "center"}} className='row justify-content-center'>
                {/* https://spec.hamilton.edu/womens-soccer-and-field-hockey-fall-in-nescac-quarterfinal-matches-e7acdb6a8c3c */}
                    <span style={{fontSize: "4.5vw", color: "white"}}>Partner with Us!</span>
                        <br></br>

                    <span style={{fontSize: "2vw", color: "white"}}>
                        We want to help you grow engagement in athletics, better your campus community, and enhance the college experience for your students.
                    </span>

                <PartnerForm />
                
                <Footer />
            </Row>
        </Fade>
        </div>
    )
}

const PartnerSection = () => {
    return (
        <div style={{marginTop: "10vw"}}>        
            <PartnerSection1 />
            <PartnerSection2 />
            <PartnerSection3 />
            
        </div>
    )
}

const NewHome = () => {
    return (
        <div id='container'>
            <Header/>
            <div style={{height: "100vh", backgroundColor: "black", paddingTop: "50px", width: "100%"}}>
                <Container style={{marginLeft: "10vw", marginRight: "10vw", maxWidth: "80%"}}>
                    <FirstView />
                    <StudentSection />
                    <PartnerSection />  
                </Container>

            </div>
            <BackToTopButton />
        </div>
    );
};



// const SportsTicker = () => {
//     return (
//         <Row>
//             <Col xs={12}>
//             <div id="animated-text-strip">
//                 <span class='marquee'>Lacrosse - Track and Field - Field Hockey - Volleyball - Baseball - Tennis - Softball - Hockey - Swimming and Diving - Football - Soccer - Golf - Rowing - Squash - &nbsp;</span>
//                 <span class='marquee'>Lacrosse - Track and Field - Field Hockey - Volleyball - Baseball - Tennis - Softball - Hockey - Swimming and Diving - Football - Soccer - Golf - Rowing - Squash - &nbsp;</span>
//                 <span class='marquee'>Lacrosse - Track and Field - Field Hockey - Volleyball - Baseball - Tennis - Softball - Hockey - Swimming and Diving - Football - Soccer - Golf - Rowing - Squash - &nbsp;</span>
//             </div>

//             <div id="animated-text-strip">
//                 <span class='reversemarquee'>Lacrosse - Track and Field - Field Hockey - Volleyball - Baseball - Tennis - Softball - Hockey - Swimming and Diving - Football - Soccer - Golf - Rowing - Squash - &nbsp;</span>
//                 <span class='reversemarquee'>Lacrosse - Track and Field - Field Hockey - Volleyball - Baseball - Tennis - Softball - Hockey - Swimming and Diving - Football - Soccer - Golf - Rowing - Squash - &nbsp;</span>
//                 <span class='reversemarquee'>Lacrosse - Track and Field - Field Hockey - Volleyball - Baseball - Tennis - Softball - Hockey - Swimming and Diving - Football - Soccer - Golf - Rowing - Squash - &nbsp;</span>
//             </div>

//             <div id="animated-text-strip">
//                 <span class='marquee'>Lacrosse - Track and Field - Field Hockey - Volleyball - Baseball - Tennis - Softball - Hockey - Swimming and Diving - Football - Soccer - Golf - Rowing - Squash - &nbsp;</span>
//                 <span class='marquee'>Lacrosse - Track and Field - Field Hockey - Volleyball - Baseball - Tennis - Softball - Hockey - Swimming and Diving - Football - Soccer - Golf - Rowing - Squash - &nbsp;</span>
//                 <span class='marquee'>Lacrosse - Track and Field - Field Hockey - Volleyball - Baseball - Tennis - Softball - Hockey - Swimming and Diving - Football - Soccer - Golf - Rowing - Squash - &nbsp;</span>
//             </div>

//             <div id="animated-text-strip">
//                 <span class='reversemarquee'>Lacrosse - Track and Field - Field Hockey - Volleyball - Baseball - Tennis - Softball - Hockey - Swimming and Diving - Football - Soccer - Golf - Rowing - Squash - &nbsp;</span>
//                 <span class='reversemarquee'>Lacrosse - Track and Field - Field Hockey - Volleyball - Baseball - Tennis - Softball - Hockey - Swimming and Diving - Football - Soccer - Golf - Rowing - Squash - &nbsp;</span>
//                 <span class='reversemarquee'>Lacrosse - Track and Field - Field Hockey - Volleyball - Baseball - Tennis - Softball - Hockey - Swimming and Diving - Football - Soccer - Golf - Rowing - Squash - &nbsp;</span>
//             </div>

//             <div id="animated-text-strip">
//                 <span class='marquee'>Lacrosse - Track and Field - Field Hockey - Volleyball - Baseball - Tennis - Softball - Hockey - Swimming and Diving - Football - Soccer - Golf - Rowing - Squash - &nbsp;</span>
//                 <span class='marquee'>Lacrosse - Track and Field - Field Hockey - Volleyball - Baseball - Tennis - Softball - Hockey - Swimming and Diving - Football - Soccer - Golf - Rowing - Squash - &nbsp;</span>
//                 <span class='marquee'>Lacrosse - Track and Field - Field Hockey - Volleyball - Baseball - Tennis - Softball - Hockey - Swimming and Diving - Football - Soccer - Golf - Rowing - Squash - &nbsp;</span>
//             </div>
//             </Col>
//         </Row>
//     );
// }

export default NewHome;