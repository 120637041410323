import HeaderAdmin from "../../shared/Header/HeaderAdmin.js";
import Footer from "../../shared/Footer/Footer";
import "./pastEvents.css"
import {useEffect, useState} from "react";
import getPastEvents from "../../shared/hooks/getPastEvents";
import getEventInformation from "../../shared/hooks/getEventInformation";
import { Row, Col, Container} from "react-bootstrap";
import ListGroup from 'react-bootstrap/ListGroup';
import Tab from 'react-bootstrap/Tab';
import { eventDateTimeFormatter } from "../../shared/formComponents.js";
import { Doughnut, Pie, Line, Scatter } from "react-chartjs-2";

const PastEvents = ({ token }) => {

    const [pastEvents, setPastEvents] = useState([]);
    const [pastEventsInfo, setPastEventsInfo] = useState([]);
    const [updateTable, setUpdateTable] = useState('');
    const [eventIndex, setEventIndex] = useState('');

    useEffect(() => {
        async function update() {
            const pe = await getPastEvents(token, false);
            setPastEvents(pe);
            let temp = []
            for (let i = 0; i < pe.length; i++) {
                let eventId = pe[i]['eventId']
                let response = await getEventInformation(eventId, token)
                temp.push(response[eventId])
            }
            setPastEventsInfo(temp) 
        }
        update();
    }, [])

    const EventDetailClassYearPieChart = ({ data }) => {

        // grabbing all unique class years
        const uniqueClassYears = [];
        data.map(classYear => {
            if (uniqueClassYears.indexOf(classYear) === -1) {
                uniqueClassYears.push(classYear)
            }
        });
        
        // getting counts for all class years
        const classYearCounts = [];
        uniqueClassYears.map(classYear => {
            classYearCounts.push(data.filter(val => val === classYear).length);
        })

        const graphData = {
            labels: uniqueClassYears,
            datasets: [
                {
                data: classYearCounts,
                borderWidth: 1,
                },
            ],
        };
    
        return (
            <div>
                <Doughnut data={graphData} />
            </div>
        ) 
    }

    const EventDetailGenderPieChart = ({ data }) => {        
        // getting counts for all gender years
        const genders = [0, 1, 3]
        const genderCounts = [];
        genders.map(gender => {
            genderCounts.push(data.filter(val => val === gender).length);
        })

        const graphData = {
            labels: ["Male", "Female", "Prefer Not to Say"],
            datasets: [
                {
                data: genderCounts,
                borderWidth: 1,
                },
            ],
        };
    
        return (
            <div>
                <Doughnut data={graphData} />
            </div>
        ) 
    }

    const EventDetailCheckInTimeLineChart = ({ data, startTime, endTime }) => {
        const yvalues = Array.from(Array(data.length).keys())
        const scatterData = []
        data.map((val, i) => {
            scatterData.push({x: val, y: yvalues[i]})
        })
        const graphData = {
            // labels: timevalues,
            datasets: [
                {
                data: scatterData,
                borderWidth: 1,
                },
            ],

        };

        const options = {
            scales: {
                x: {type: 'time'}
            }
        }
    
        return (
            <div>
                <button onClick={() => console.log(scatterData)}>BALLS</button>
                <Scatter data={graphData} height={data.length + 2} options={options}/>
            </div>
        ) 
    }

    const EventDetailView = ({ data, title, startTime, endTime, location, sport }) => {
        return (
            <Container>
                <Row className='eventDetailTitle'>
                    {title}
                </Row>
                <Row className='eventDetailSubtitle'>
                    {eventDateTimeFormatter(startTime, endTime)}
                </Row>

                <Row className='eventDetailSubtitle'>
                    {sport} at {location}
                </Row>

                <Row className='eventDetailAttendees'><span style={{color: 'lightgreen'}}>{data.length}</span></Row>
                <Row className='eventDetailSubtitle'>Attendees</Row>

                <hr></hr>
                
                <Row>
                    <Col>
                        <div className='classYearTitle'>Class Year Distribution</div>
                        <EventDetailClassYearPieChart data={data.map(val => val['classYear'])}/>
                    </Col>

                    <Col>
                        <div className='classYearTitle'>Gender Distribution</div>
                        <EventDetailGenderPieChart data={data.map(val => val['gender'])}/>
                    </Col>
                </Row>

                {/* <Row>
                    <Col>
                        <EventDetailCheckInTimeLineChart data={data.map(val => val['checkInTime'])} startTime={startTime} endTime={endTime}/>
                    </Col>
                </Row> */}

                {/* <ul>
                    {data.map((attendee, index) => (
                        <li key={index}>{attendee['gender']} {attendee['checkInTime']} {attendee['classYear']}</li>
                    ))}
                </ul> */}
            </Container>
        );
    };

    return (
        <div className="app">
            <HeaderAdmin/>

            <Col>
                <Tab.Container defaultActiveKey={-1}>
                    <Row>

                        <Col xs={4} className='pastEventColumn'>
                            <ListGroup>
                                {pastEvents.map((data, index) => (
                                    <ListGroup.Item action href={'#' + index}>{data['title']}</ListGroup.Item>
                                ))} 
                            </ListGroup>
                        </Col>

                        <Col xs={8} className='pastEventColumn'> 
                            <Tab.Content>
                                {pastEventsInfo.length > 0 && pastEventsInfo.map((data, index) => (
                                    <Tab.Pane id={index} eventKey={'#' + index}> 
                                        <EventDetailView 
                                            data={data} 
                                            title={pastEvents[index]['title']}
                                            startTime={pastEvents[index]['time']}
                                            endTime={pastEvents[index]['endTime']}
                                            location={pastEvents[index]['location']}
                                            sport={pastEvents[index]['type']}
                                                />
                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </Col>
                    </Row>

                </Tab.Container>
            </Col>
            <Footer/>
        </div>
    );
};

export default PastEvents;