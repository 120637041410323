// src/components/Header/Header.js
import React from 'react';
import './Header.css';
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FanstandLogo } from './Header';

const HeaderOnlyLoginLink = () => {
    return (
        <header id="nostickheader">
            <Row className="correctSizedRow">
                <FanstandLogo />
                <Col style={{display: 'flex', flexDirection: 'column-reverse'}}>
                    <nav>
                        <ul>                                
                            <li>
                                <Link style={{color: 'inherit', textDecoration: 'inherit', fontSize: '1.5vw'}}
                                        to="/admin">
                                <div className="headerLink">
                                    Log In
                                </div>
                            </Link>
                            </li>
                        </ul>
                    </nav>
                </Col>
            </Row>
        </header>
    );
};

export default HeaderOnlyLoginLink;
