import {SERVER_ADDRESS} from "../constants";
import toast from "react-hot-toast";

async function editEvent(credentials, token) {
    const endpoint = SERVER_ADDRESS + '/api/v1/event/edit'
    const toastId = toast.loading("Attempting to edit event...")

    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token['accessToken']
        },
        body: JSON.stringify(credentials)
    }).then(data => data)

    if (response.status === 200) {
        toast.dismiss()
        toast.success("Event was edited successfully!", {id: toastId})
    } else {
        toast.dismiss()
        toast.error("Error occurred when editing event.", {id: toastId})
    }
}

export default editEvent;