import Header from "../../shared/Header/NoLinkHeader";
import Footer from "../../shared/Footer/Footer";
import ErrorCircle from '@mui/icons-material/Error';

const Error = () => {
    return (
        <div>
            <Header/>

            <div style={{marginTop: "17vh", textAlign: "center"}}>
                <ErrorCircle style={{color: "red", transform: 'scale(4)'}}/>
            </div>

            <h2 style={{color: "white", margin: "5vw", textAlign: "center", fontSize: "3.5vh"}}>
                Hm. You weren't supposed to end up here...
            </h2>

            <div style={{paddingBottom: "12vh"}} >
                <p style={{color: "white", margin: "10vh", textAlign: "center", fontSize: "2.5vh"}}>
                    Try clicking the logo in the top left to return home. If the problem persists, shoot us an 
                    email at <a href={"mailto:info@fanstandapp.com"}>info@fanstandapp.com</a>
                </p>
            </div>

            <Footer/>
        </div>
    );
}

export default Error;