// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyA31ScSQOKldxyoOhS3z1bq1AvRYUEBdEc",
  authDomain: "todoapp-eeeb7.firebaseapp.com",
  projectId: "fanstand-feb95",
  storageBucket: "fanstand-feb95.appspot.com",
  messagingSenderId: "486981916491",
  appId: "1:486981916491:ios:26d0485b3a9516e8e87ace"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export default app;