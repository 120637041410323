// src/components/Header/Header.js
import React from 'react';
import './Header.css';
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FanstandLogo } from './Header';

const NoLinkHeader = () => {
    return (
        <>
            <header className="header">
                <Row className="correctSizedRow">
                    <FanstandLogo />
                </Row>
            </header>

        </>
    );
}

export default NoLinkHeader;
