// src/App.js
import React, {useState, useEffect} from 'react';
import './App.css';
import MobileApp from './MobileApp';
import DesktopApp from './DesktopApp';
// LOGIN STUFF
// ALL LOGIN INFO IS FROM
// https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications

function App() {
    const [width, setWidth] = useState(window.innerWidth);
    const [mobileDevice, setMobileDevice] = useState(false)

    function handleWindowSizeChange() {
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
        const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);
        setMobileDevice(isMobile || isTablet);
        // console.log(mobileDevice, userAgent, isMobile, isTablet)
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (isMobile) ? <MobileApp /> : <DesktopApp />;
}

export default App;