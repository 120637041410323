import HeaderLogin from "../../shared/Header/NoLinkHeader.js";
import Footer from "../../shared/Footer/Footer.js";
import Form from "react-bootstrap/Form";
import { FormControl, FormGroup, FormLabel} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useEffect, useState} from "react";
import "./Login.css"
import loginUser from "../../shared/hooks/loginUser";
import createUser from "../../shared/hooks/createUser";
import getSchools from "../../shared/hooks/getSchools";
import {EventFormControl, SubmitButton} from "../../shared/formComponents";
import { useNavigate } from "react-router-dom";


const Login = ({ setToken }) => {

    const navigate = useNavigate()
    // universal
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // registering a new user
    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [schoolId, setSchoolId] = useState('');
    const [classYear, setClassYear] = useState('');
    const [gender, setGender] = useState('');

    const [newUserCreated, setNewUserCreated] = useState(false);

    // display flags
    const [loginNotRegister, setLoginNotRegister] = useState(true);


    // schools
    // const [schools, setSchools] = useState([]);
    // useEffect(() => {
    //     async function schools() {
    //         const result = await getSchools();
    //         if (!result[0]) {
    //             setSchools([{schoolId: '-1', name: result[1]}])
    //         } else {
    //             setSchools(result[1]);
    //         }
    //         console.log("UPDATED")
    //     }
    //     schools();
    // }, []);

    const handleLogin = async e => {
        e.preventDefault();
        const token = await loginUser({
            email: email,
            password: password
        });

        if (token) {
            setToken(token);
        }
        navigate('/admin');
    }

    const handleNewUser = async e => {
        e.preventDefault();
        await createUser({
            firstName,
            lastName,
            password,
            email,
            schoolId,
            classYear,
            gender,
            username
        });
    }

    return (
        <div className="app">
            <HeaderLogin />
            <div className='loginForm'>
                {loginNotRegister && <Form onSubmit={handleLogin}>
                    <h1 style={{fontSize: "2rem", color: "white"}}>Admin Login</h1>
                        <EventFormControl value={email}
                                          onChange={(e) => setEmail(e.target.value)}
                                          placeholder="Email" />

                        <EventFormControl value={password}
                                          type='password'
                                          placeholder='Password'
                                          onChange={(e) => setPassword(e.target.value)}/>

                    <SubmitButton text='Login' />

                    {/*<Button onClick={() => {*/}
                    {/*    setLoginNotRegister(false); setNewUserCreated(false);*/}
                    {/*}} style={{*/}
                    {/*    backgroundColor: 'blue',*/}
                    {/*    fontWeight: 'bold',*/}
                    {/*    fontSize: '20px',*/}
                    {/*    border: 'none',*/}
                    {/*    marginLeft: '10px'*/}
                    {/*}}>*/}
                    {/*    Create New User*/}
                    {/*</Button>*/}
                </Form>}

                {/*{!loginNotRegister && <Form onSubmit={handleNewUser}>*/}
                {/*    <h1>Create Admin</h1>*/}
                {/*    <hr/>*/}
                {/*    <FormGroup>*/}
                {/*        <FormLabel className='formLabel'>First Name</FormLabel>*/}
                {/*        <FormControl className='formControl' value={firstName} required*/}
                {/*                     onChange={(e) => setFirstName(e.target.value)}></FormControl>*/}
                {/*    </FormGroup>*/}

                {/*    <FormGroup>*/}
                {/*        <FormLabel className='formLabel'>Last Name</FormLabel>*/}
                {/*        <FormControl className='formControl' value={lastName} required*/}
                {/*                     onChange={(e) => setLastName(e.target.value)}></FormControl>*/}
                {/*    </FormGroup>*/}

                {/*    <FormGroup>*/}
                {/*        <FormLabel className='formLabel'>Username</FormLabel>*/}
                {/*        <FormControl className='formControl' value={username} required*/}
                {/*                     onChange={(e) => setUsername(e.target.value)}></FormControl>*/}
                {/*    </FormGroup>*/}

                {/*    <FormGroup>*/}
                {/*        <FormLabel className='formLabel'>Email</FormLabel>*/}
                {/*        <FormControl className='formControl' value={email}*/}
                {/*                     onChange={(e) => setEmail(e.target.value)}></FormControl>*/}
                {/*    </FormGroup>*/}

                {/*    <FormGroup>*/}
                {/*        <FormLabel className='selectLabel'>School</FormLabel>*/}
                {/*        <Form.Select className='selectBox' required onChange={(e) => setSchoolId(e.target.value)}*/}
                {/*                     value={schoolId} aria-label="Please Select a Sport">*/}
                {/*            <option hidden value="">Please Select Your School</option>*/}
                {/*            {schools.map((data, index) => (*/}
                {/*                <option key={index} className='sportOption'*/}
                {/*                        value={data['schoolId']}>{data['name']}</option>*/}
                {/*            ))}*/}

                {/*        </Form.Select>*/}
                {/*    </FormGroup>*/}

                {/*    <FormGroup>*/}
                {/*        <FormLabel className='formLabel'>Class Year</FormLabel>*/}
                {/*        <FormControl className='formControl' value={classYear} type='number' required*/}
                {/*                     onChange={(e) => setClassYear(e.target.value)}></FormControl>*/}
                {/*    </FormGroup>*/}

                {/*    <FormGroup>*/}
                {/*        <FormLabel className='selectLabel'>Gender</FormLabel>*/}
                {/*        <Form.Select className='selectBox' required onChange={(e) => setGender(e.target.value)}*/}
                {/*                     value={gender} aria-label="Please Select a Sport">*/}
                {/*            <option hidden value="">Please Select Your Gender</option>*/}
                {/*            <option value='0'>Male</option>*/}
                {/*            <option value='1'>Female</option>*/}
                {/*            <option value='2'>Other</option>*/}
                {/*        </Form.Select>*/}
                {/*    </FormGroup>*/}

                {/*    <Form.Group>*/}
                {/*        <Form.Label className='formLabel'>Password</Form.Label>*/}
                {/*        <FormControl className='formControl' value={password} type='password' required*/}
                {/*                     onChange={(e) => setPassword(e.target.value)}/>*/}
                {/*    </Form.Group>*/}

                {/*    <hr/>*/}

                {/*    <Button variant='primary' type='submit' style={{*/}
                {/*        backgroundColor: 'blue',*/}
                {/*        fontWeight: 'bold',*/}
                {/*        fontSize: '20px',*/}
                {/*        border: 'none',*/}
                {/*        marginLeft: '10px'*/}
                {/*    }}>*/}
                {/*        Create New User*/}
                {/*    </Button>*/}

                {/*    <Button onClick={() =>  {*/}
                {/*        setLoginNotRegister(true); setNewUserCreated(false);*/}
                {/*    }} style={{*/}
                {/*        backgroundColor: 'green',*/}
                {/*        fontWeight: 'bold',*/}
                {/*        fontSize: '20px',*/}
                {/*        border: 'none',*/}
                {/*        marginLeft: '10px'*/}
                {/*    }}>*/}
                {/*        Return to Login*/}
                {/*    </Button>*/}
                {/*</Form>}*/}
                </div>
            <Footer/>
        </div>
    );
}

export default Login;