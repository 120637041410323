import HeaderAdmin from "../../shared/Header/HeaderAdmin.js";
import Footer from "../../shared/Footer/Footer";
import "./EventManager.css"

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FormControl, FormGroup, FormLabel} from "react-bootstrap";
import {useEffect, useState} from "react";
import Accordion from "react-bootstrap/Accordion";
import editEvent from "../../shared/hooks/editEvent";
import deleteEvent from "../../shared/hooks/deleteEvent";
import addEvent from "../../shared/hooks/addEvent";

// icons
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import EventManagerMap from "./EventManagerMap";
import uploadImage from "../../shared/hooks/uploadImage";
import addRedeemEvent from "../../shared/hooks/addRedeemEvent";

import toast from "react-hot-toast";
import DisplayEventLocationMap from "./DisplayEventLocationMap";
import deleteRedeemEvent from "../../shared/hooks/deleteRedeemEvent";
import EventManagerSwitchFormButtons from "../../shared/EventManagerSwitchFormButtons";
import {
    ClearButton,
    EditButton, eventDateTimeFormatter,
    EventFormControl,
    EventFormSelect, HeaderText,
    InfoText,
    WhiteInfoText,
    SubmitButton, timeOffset
} from "../../shared/formComponents";
import getFutureEvents from "../../shared/hooks/getFutureEvents";
import LiveEvents from "./LiveEvents.js";

const EventManager = ({ token }) => {
    const sports = [
        {name: "Cross Country"},
        {name: "Swimming and Diving"},
        {name: "Track and Field"},
        {name: "Rowing"},
        {name: "Golf"},
        {name: "Baseball"},
        {name: "Football"},
        {name: "Men's Basketball"},
        {name: "Men's Ice Hockey"},
        {name: "Men's Lacrosse"},
        {name: "Men's Soccer"},
        {name: "Men's Squash"},
        {name: "Men's Tennis"},
        {name: "Women's Basketball"},
        {name: "Field Hockey"},
        {name: "Women's Ice Hockey"},
        {name: "Women's Lacrosse"},
        {name: "Women's Soccer"},
        {name: "Women's Softball"},
        {name: "Women's Squash"},
        {name: "Women's Tennis"},
        {name: "Women's Volleyball"},
        {name: "Other"}
    ]

    const athleticLocations = [
        {name:"Alumni Gym", latitude: "43.053229868102946", longitude: "-75.407392200527"},
        {name:"Bristol Pool", latitude: "43.053331387738204", longitude: "-75.40798992744365"},
        {name:"Goodfriend Field", latitude: "43.05601407384956", longitude: "-75.40303632191267"},
        {name:"Hamilton Cross Country Course", latitude: "43.05463520974613", longitude: "-75.41241781053245"},
        {name:"Little Squash Center", latitude: "43.054606962426135", longitude: "-75.40803207002928"},
        {name:"Loop Road Athletic Complex (Baseball)", latitude: "43.05740091445656", longitude: "-75.40947896970657"},
        {name:"Loop Road Athletic Complex (Softball)", latitude: "43.05695408542683", longitude: "-75.40835975261191"},
        {name:"Love Field", latitude: "43.05289508908832", longitude: "-75.41024396688113"},
        {name:"Margaret Bundy Scott Field House", latitude: "43.05425977152404", longitude: " -75.40800554820225"},
        {name:"Pritchard Track", latitude: "43.05289508908832", longitude: "-75.41024396688113"},
        {name:"Sage Rink", latitude: "43.053750419295184", longitude: "-75.40729468824634"},
        {name:"Steuben Field", latitude: "43.05323793298581", longitude: "-75.40872600973695"},
        {name:"Tietje Family Tennis Center", latitude: "43.054591993825504", longitude: "-75.41043653328849"},
        {name:"Withiam Field", latitude: "43.0555125038203", longitude: "-75.40445778115877"},
        {name:"Other", latitude: "0", longitude: "0"}
    ]

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [location, setLocation] = useState('')

    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [radius, setRadius] = useState(50);

    const [sport, setSport] = useState('')
    const [date, setDate] = useState('')
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const [rewardPoints, setRewardPoints] = useState('10')
    const [gameOfTheWeek, setGameOfTheWeek] = useState(false)
    const [enabled, setEnabled] = useState( true);
    const [coverPhoto, setCoverPhoto] = useState('')

    const [accordionActiveKey, setAccordionActiveKey] = useState('-1')
    const [redeemAccordionActiveKey, setRedeemAccordionActiveKey] = useState('-1');
    const [events, setEvents] = useState([]);
    const [activeEvents, setActiveEvents] = useState([]);
    const [redeemEvents, setRedeemEvents] = useState([])
    const [updateTable, setUpdateTable] = useState(0);

    const [editEventId, setEditEventId] = useState(-1);
    const [editEventButton, setEditEventButton] = useState(false);

    const [userEvent, setUserEvent] = useState(true);

    // CITE: https://stackblitz.com/edit/react-4z6pid?file=src%2FApp.js
    // DESC: Stolen right from this lmfao
    const handleCoverPhotoChange = async e => {
        let files = e.target.files;

        if (files.length > 0) {
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);

            reader.onload = async e => {
                const response = await uploadImage(files[0], token)
                // console.log(response.toString())

                setCoverPhoto(response.toString())
            };
        }
    };

    useEffect(() => {
        async function update() {
            const allEvents = await getFutureEvents(token);
            if (allEvents[0] === 'error') {
                setEvents(allEvents)
                setRedeemEvents(allEvents)
                return
            }
            setEvents(allEvents.filter(event => event.type != 'redemption'));
            setRedeemEvents(allEvents.filter(event => event.type == 'redemption'))
            // setActiveEvents([allEvents[0]])
        }
        update();
        setEditEventButton(false);
    }, [updateTable])

    // ADD EVENT SECTION
    const handleAddEvent = async e => {
        e.preventDefault();
        let finalTime = new Date(date + 'T' + startTime)
        let finalEndTime = new Date(date + 'T' + endTime)
        finalTime.toLocaleString('en-US', { timeZone: 'America/New_York' })
        finalEndTime.toLocaleString('en-US', { timeZone: 'America/New_York' })

        if (!timeCorrectnessCheck(date, finalTime, finalEndTime)) {
            return
        }
        await addEvent({
            title: title,
            description: description,
            location: location,
            points: rewardPoints,
            time: finalTime.toISOString(),
            endTime: finalEndTime.toISOString(),
            imageName: coverPhoto,
            longitude: lng,
            latitude: lat,
            radius: radius,
            enabled: enabled,
            type: sport,
            special: gameOfTheWeek,
            schoolId: 1
        }, token);
        setUpdateTable(updateTable + 1);
        // clearFields()
    };

    // GET EVENT SECTION


    // async function getActiveEvents() {
    //     return fetch('http://localhost:8080/api/v1/event/activeEvents', {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + token['accessToken']
    //         },
    //     }).then(data => data.json()).then(json => json)
    // }

    // DELETE EVENTS
    const handleDeleteEvent = async (event, data) => {
        event.preventDefault();
        event.stopPropagation();
        await deleteEvent(data['eventId'], token);
        setUpdateTable(updateTable + 1);
    }

    // EDIT EVENT
    const handleEditEvent = async e => {
        e.preventDefault();
        let finalTime = new Date(date + 'T' + startTime)
        let finalEndTime = new Date(date + 'T' + endTime)
        finalTime.toLocaleString('en-US', { timeZone: 'America/New_York' })
        finalEndTime.toLocaleString('en-US', { timeZone: 'America/New_York' })

        if (!timeCorrectnessCheck(date, finalTime, finalEndTime)) {
            return
        }
        await editEvent({
            eventId: editEventId,
            title: title,
            description: description,
            location: location,
            points: rewardPoints,
            time: finalTime.toISOString(),
            endTime: finalEndTime.toISOString(),
            imageName: coverPhoto,
            longitude: lng,
            latitude: lat,
            radius: radius,
            enabled: enabled,
            type: sport,
            special: gameOfTheWeek,
            schoolId: 1,
        }, token);
        setUpdateTable(updateTable + 1);
        clearFields()
    }
    // **************************************************
    // REDEEM EVENT SECTION
    // **************************************************
    const handleAddRedeemEvent = async e => {
        e.preventDefault();

        let finalTime = new Date(date + 'T' + startTime)
        let finalEndTime = new Date(date + 'T' + endTime)
        finalTime.toLocaleString('en-US', { timeZone: 'America/New_York' })
        finalEndTime.toLocaleString('en-US', { timeZone: 'America/New_York' })

        // console.log(finalTime, finalEndTime)

        if (!timeCorrectnessCheck(date, finalTime, finalEndTime)) {
            return
        }

        await addRedeemEvent({
            title: title,
            description: description,
            location: location,
            points: 0,
            time: finalTime.toISOString(),
            endTime: finalEndTime.toISOString(),
            longitude: lng,
            image_id: 11,
            latitude: lat,
            radius: 0,
            enabled: enabled,
            type: 'redemption',
            special: false,
            schoolId: 1,
        }, token);
        // clearFields()
        setUpdateTable(updateTable + 1);
    };

    const handleDeleteRedeemEvent = async (event, data) => {
        event.preventDefault();
        event.stopPropagation();
        await deleteRedeemEvent(data['redemptionEventId'], token);
        setUpdateTable(updateTable + 1);
    }

    // **************************************************

    const accordionEditEvent = (e, data) => {
        setUserEvent(true)
        setEditEventId(data['eventId']);
        populateFields(e, data);
        e.stopPropagation();
        setEditEventButton(true);
    }

    // UTILITY SECTION
    const populateFields = (event, data) => {
        setTitle(data["title"])
        setDescription(data['description'])

        setLocation(data['location'])

        setLat(data['latitude'])
        setLng(data['longitude'])
        setRadius(data['radius'])

        setSport(data['type'])

        let finalTime = new Date(data['time'])
        let finalEndTime = new Date(data['endTime'])

        finalTime = finalTime.setHours(finalTime.getHours() - timeOffset)
        finalEndTime = finalEndTime.setHours(finalEndTime.getHours() - timeOffset)

        finalTime = new Date(finalTime).toISOString()
        finalEndTime = new Date(finalEndTime).toISOString()

        setDate(finalTime.slice(0, 10))
        setStartTime(finalTime.slice(11, 16))
        setEndTime(finalEndTime.slice(11, 16))

        setRewardPoints(data['points'])
        setGameOfTheWeek(data['special'])
        setEnabled(data['enabled'])
        setCoverPhoto(data['imageName'])
    };

    function timeCorrectnessCheck(date, startTime, endTime) {
        let current = new Date()
        if (Date.parse(startTime) <= current) {
            toast.error("Invalid start time: add a date in the future")
            return false
        }

        if (Date.parse(startTime) >= Date.parse(endTime)) {
            toast.error("Start time is later than end time")
            return false
        }
        return true
    }

    const clearFields = () => {
        setTitle("");
        setDescription("");
        setLocation("");
        setSport("");
        setDate("");
        setStartTime("");
        setEndTime("");
        setRewardPoints("10");
        setGameOfTheWeek(false);
        setRadius(50);
        setCoverPhoto(null);
        setEnabled(true);
        setLat(0);
        setLng(0);
        setEditEventButton(false)
    };

    const handleAccordionHeaderClick = (event, index) => {
        if (!(event.target.children.length === 0)) { // if the header (but not the buttons) were selected
            accordionActiveKey === index ? setAccordionActiveKey('-1') : setAccordionActiveKey(index);
        }
    };

    const handleRedeemAccordionHeaderClick = (event, index) => {
        if (!(event.target.children.length === 0)) { // if the header (but not the buttons) were selected
            redeemAccordionActiveKey === index ? setRedeemAccordionActiveKey('-1') : setRedeemAccordionActiveKey(index);
        }
    };

    function getLocationLatitude(location) {
        for (var i = 0; i < athleticLocations.length; i++) {
            if (athleticLocations[i]['name'] === location) {
                return athleticLocations[i]['latitude'];
            }
        }
    }

    function getLocationLongitude(location) {
        for (var i = 0; i < athleticLocations.length; i++) {
            if (athleticLocations[i]['name'] === location) {
                return athleticLocations[i]['longitude'];
            }
        }
    }

    return (
        <div className="app">
            <HeaderAdmin/>
            {activeEvents.length > 0 && <LiveEvents activeEvents={activeEvents}/>}
            <Row style={{margin: "0"}}>
                {userEvent && <Col style={{padding: "0"}} sm={7}>
                    <EventManagerSwitchFormButtons
                        userEvent={userEvent}
                        setUserEvent={setUserEvent}
                        clear={clearFields}/>
                    <div className='createEvent'>
                        <Form onSubmit={handleAddEvent}>
                            <EventFormControl value={title}
                                              onChange={(e) => setTitle(e.target.value)}
                                              placeholder="Title"
                            />

                            <EventFormControl value={description}
                                              as='textarea'
                                              onChange={(e) => setDescription(e.target.value)}
                                              placeholder="Description"
                            />

                            <Row className='mb-3'>
                                <EventFormSelect value={sport}
                                                 name="Sports"
                                                 onChange={(e) => setSport(e.target.value)}
                                                 data={sports} />

                                <EventFormSelect value={location}
                                                 name="Location"
                                                 onChange={(e) => {
                                                     setLocation(e.target.value);
                                                     setLat(getLocationLatitude(e.target.value));
                                                     setLng(getLocationLongitude(e.target.value))
                                                 }}
                                                 data={athleticLocations} />
                            </Row>

                            <div style={{border: "2px solid black", borderRadius: "3px", marginTop: "-5px", padding: "10px", marginBottom: "10px"}}>
                                <WhiteInfoText text="Select the precise event location with the map. Click on map to update marker. Change
                                           slider to update radius."
                                />
                                <FormGroup>
                                    <Form.Label style={{color:"white"}}>Event Radius: <span
                                        style={{fontWeight: 'bold', color:"white"}}>{radius}m</span></Form.Label>
                                    <br/>
                                    <Form.Range
                                        value={radius}
                                        onChange={(e) => setRadius(parseInt(e.target.value))}
                                        min='0'
                                        max='500'
                                        style={{width: '100%'}}
                                    />
                                </FormGroup>
                                <EventManagerMap
                                    lat={lat}
                                    lng={lng}
                                    setLat={setLat}
                                    setLng={setLng}
                                    radius={radius}
                                />
                            </div>

                            <Row className="mb-3" fluid>
                                <Col xs='auto' style={{paddingLeft: "10", paddingTop: '7px', color: "white"}}>
                                    On:
                                </Col>

                                <Col style={{paddingLeft: "0"}}>
                                    <EventFormControl value={date}
                                                      type='date'
                                                      onChange={(e) => setDate(e.target.value)} />

                                </Col>

                                <Col xs='auto' style={{padding: "0", paddingTop: '7px', color: "white"}}>
                                    from:
                                </Col>

                                <Col>
                                    <EventFormControl value={startTime}
                                                      type='time'
                                                      onChange={(e) => setStartTime(e.target.value)} />
                                </Col>

                                <Col xs='auto' style={{padding: "0", paddingTop: '7px', color: "white"}}>
                                    to:
                                </Col>

                                <Col>
                                    <EventFormControl value={endTime}
                                                      type='time'
                                                      onChange={(e) => setEndTime(e.target.value)} />
                                </Col>
                            </Row>

                            <Row style={{marginTop: "-5px"}}>
                                <Col fluid>
                                    <FormGroup as={Row} style={{marginBottom: "10px"}}>
                                        <Col xs='auto' style={{padding: "0", paddingTop: '7px', paddingLeft: "15px"}}>
                                        <FormLabel style={{fontSize: "1rem", display: "flex", color:"white"}}>Reward
                                            Points</FormLabel>
                                        </Col>
                                        <Col>
                                        <FormControl style={{border: "2px solid black"}} required
                                                     value={rewardPoints} type='number'
                                                     onChange={(e) => setRewardPoints(e.target.value)}/>
                                        </Col>
                                    </FormGroup>

                                    <Row>
                                        <Col xs='auto'>
                                        <Form.Check 
                                                    style={{color: "white"}}
                                                    inline
                                                    reverse
                                                    label="Game of the Week?"
                                                    name="gotwCheckbox"
                                                    type="checkbox"
                                                    checked={gameOfTheWeek}
                                                    onChange={(e) => setGameOfTheWeek(e.target.checked)}
                                        />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs='auto'>
                                            <Form.Check 
                                                        style={{color: "white"}}
                                                        inline
                                                        reverse
                                                        label="Visible to Users?"
                                                        name="visibleCheckbox"
                                                        type="checkbox"
                                                        checked={enabled}
                                                        onChange={(e) => setEnabled(e.target.checked)}
                                            />
                                        </Col>
                                    </Row>

                                </Col>

                                <Form.Group as={Col} fluid className='mb-3' style={{alignContent: 'center', color: "white"}}>
                                    <FormControl type="file" className='photoForm'
                                                 accept=".png,.jpg,.jpeg,.webp"
                                                 onChange={handleCoverPhotoChange}
                                    />
                                    {!coverPhoto && <p>No photo selected.</p>}
                                    <br/>
                                    {coverPhoto && <img
                                        style={{height: '100px', border: "3px solid black", borderStyle: "visible"}}
                                        alt=''
                                        src={"https://fanstandblobs.blob.core.windows.net/images/" + coverPhoto}/>}
                                </Form.Group>

                            </Row>
                            <br/>

                            <SubmitButton text='Add New Event' />
                            {editEventButton && <EditButton onClick={handleEditEvent} text='Edit Existing Event' />}
                            <ClearButton onClick={clearFields} text='Clear Fields' />

                        </Form>
                    </div>
                </Col>}

                {!userEvent && <Col style={{padding: "0"}}>
                    <EventManagerSwitchFormButtons
                        userEvent={userEvent}
                        setUserEvent={setUserEvent}
                        clear={clearFields}/>
                    <div className='createEvent'>
                        <Form onSubmit={handleAddRedeemEvent}>
                            <EventFormControl value={title}
                                                onChange={(e) => setTitle(e.target.value)}
                                                placeholder="Title"
                                />

                                <EventFormControl value={description}
                                                as='textarea'
                                                onChange={(e) => setDescription(e.target.value)}
                                                placeholder="Description"
                                />
                            <Col style={{paddingLeft: "0", paddingRight: "0", marginBottom: "10px"}}>
                                <EventFormControl value={location}
                                                  onChange={(e) => setLocation(e.target.value)}
                                                  placeholder='Location'/>

                            </Col>

                            <div style={{border: "2px solid black", borderRadius: "3px", padding: "10px", marginBottom: "10px"}}>
                                <WhiteInfoText text='Select the precise event location with the map. Click on map to update marker.' />
                                <EventManagerMap
                                    al=""
                                    location=""
                                    lat={lat}
                                    lng={lng}
                                    setLat={setLat}
                                    setLng={setLng}
                                    radius={0}
                                />
                            </div>

                            <Row className="mb-3" fluid>
                                <Col xs='auto' style={{paddingLeft: "10", paddingTop: '7px', color:"white"}}>
                                    On:
                                </Col>
                                <Form.Group as={Col} style={{paddingLeft: "0"}}>
                                    <Form.Control style={{color: "black", border: "2px solid black", borderWidth: "2px", background: "white"}} required value={date} type="date" onChange={(e) => setDate(e.target.value)}/>
                                </Form.Group>
                                <Col xs='auto' style={{padding: "0", paddingTop: '7px', color:"white"}}>
                                    from:
                                </Col>
                                <Form.Group as={Col}>
                                    <Form.Control required value={startTime} type="time" style={{color: "black", border: "2px solid black", borderWidth: "2px", background: "white"}}
                                                  onChange={(e) => setStartTime(e.target.value)}/>
                                </Form.Group>
                                <Col xs='auto' style={{padding: "0", paddingTop: '7px', color:"white"}}>
                                    to:
                                </Col>
                                <Form.Group as={Col}>
                                    <Form.Control required value={endTime} type="time" style={{color: "black", border: "2px solid black", borderWidth: "2px", background: "white"}}
                                                  onChange={(e) => setEndTime(e.target.value)}/>
                                </Form.Group>
                            </Row>

                            <FormGroup as={Row}>
                                <Col/>
                                <Form.Check inline
                                            style={{color: "white"}}
                                            reverse={true}
                                            label="Visible to Users?"
                                            name="visibleCheckbox"
                                            type="checkbox"
                                            checked={enabled}
                                            onChange={(e) => setEnabled(e.target.checked)}
                                />
                                <Col/>
                            </FormGroup>
                            <br/>
                            <SubmitButton text='Add Redeem Event' />
                            <ClearButton onClick={clearFields} text='Clear Fields' />

                        </Form>
                    </div>
                </Col>}

                <Col style={{padding: "0", backgroundColor: "#e7dec7"}} sm={5}>
                    <div className='viewEvent'>
                        <HeaderText text='View Upcoming Sports Events' />
                        <InfoText text='Click each box for more information. "Edit" and "Delete" buttons are located on the right' />
                        <Accordion activeKey={accordionActiveKey}>
                            {events.length === 0 && <p>No Upcoming Sporting Events. Try adding some!</p>}
                            {events[0] === 'error' && <p style={{color: "red"}}>We encountered an error when retrieiving your events. Please reload the page.</p>}
                            {events[0] !== 'error' && events.map((data, index) => (
                                <Accordion.Item eventKey={index} key={index}>
                                    <Accordion.Header onClick={(e) => handleAccordionHeaderClick(e, index)}>
                                        {data['title']}
                                        <br/>
                                        {eventDateTimeFormatter(data["time"], data["endTime"])}
                                        <div className='accordionButtonGroup'>
                                            <Button className='accordionEditButton'
                                                    onClick={(e) => accordionEditEvent(e, data)}>
                                                <Edit/>
                                            </Button>
                                            <Button className='accordionDeleteButton'
                                                    onClick={e => handleDeleteEvent(e, data)}>
                                                <Delete/>
                                            </Button>
                                        </div>
                                    </Accordion.Header>

                                    <Accordion.Body style={{fontSize: '1rem'}}>
                                        Description: {data["description"]}<br/>
                                        Sport: {data['type']}<br/>
                                        Reward Points: {data['points']}<br/>
                                        Game of the Week: {data['special'] && <span>Yes</span>}{!data['special'] &&
                                                    <span>No</span>} <br/>
                                        Visible to Users: {data['enabled'] && <span>Yes</span>}{!data['enabled'] &&
                                                    <span>No</span>}

                                        <p>Location: {data['location']}</p>
                                        <p>Map Location:</p>
                                        <div style={{
                                            width: "75%",
                                            margin: "0 auto",
                                            outlineStyle: "auto",
                                            outlineWidth: "100px"
                                        }}>
                                            <DisplayEventLocationMap
                                                lat={data["latitude"]}
                                                lng={data['longitude']}
                                                radius={data["radius"]}
                                                update={accordionActiveKey}
                                            />
                                        </div>
                                        <p>Cover Photo:</p>
                                        <img style={{marginBottom: '15px', border: "5px solid #555", borderStyle: "visible", height: "100px"}} alt=''
                                             src={"https://fanstandblobs.blob.core.windows.net/images/" + data['imageName']}/>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </div>

                    <div className='viewEvent' style={{paddingTop: "0"}}>
                        <HeaderText text='View Upcoming Redeem Events' />
                        <InfoText text='Click each box for more information. The "Delete"
                            buttons is located on the right' />

                        <Accordion activeKey={redeemAccordionActiveKey}>
                            {redeemEvents.length === 0 && <p>No Upcoming Redeem Events. Try adding some!</p>}
                            {events[0] === 'error' && <p style={{color: "red"}}>We encountered an error when retrieiving your events. Please reload the page.</p>}
                            {redeemEvents[0] !== 'error' && redeemEvents.map((data, index) => (
                                <Accordion.Item eventKey={index} key={index}>
                                    <Accordion.Header onClick={(e) => handleRedeemAccordionHeaderClick(e, index)}>
                                        {data['title']}
                                        <br/>
                                        {eventDateTimeFormatter(data["time"], data["endTime"])}
                                        <div className='accordionButtonGroup'>
                                            <Button className='accordionDeleteButton'
                                                    onClick={e => handleDeleteRedeemEvent(e, data)}>
                                                <Delete/>
                                            </Button>
                                        </div>
                                    </Accordion.Header>

                                    <Accordion.Body style={{fontSize: '1rem'}}>
                                        <p>Description: {data["description"]}</p>
                                        <p>Visible to Users: {data['enabled'] &&
                                            <span>Yes</span>}{!data['enabled'] &&
                                            <span>No</span>}</p>
                                        <p>Location: {data['location']}</p>
                                        <p>Map Location: </p>
                                        <div style={{
                                            width: "75%",
                                            margin: "0 auto",
                                            outlineStyle: "auto",
                                            outlineWidth: "100px",
                                        }}>
                                            <DisplayEventLocationMap
                                                lat={data["latitude"]}
                                                lng={data['longitude']}
                                                radius={0}
                                                update={redeemAccordionActiveKey}
                                            />
                                        </div>
                                        <br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </div>
                </Col>
            </Row>
            <Footer/>
        </div>
    );
};

export default EventManager;