// src/components/Header/Header.js
import React from 'react';
import './Header.css';
import { Outlet, Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const MobileFanstandLogo = () => {
    return (
        <Col xs='auto' style={{alignItems: "center", justifyContent: "center", position: "relative", paddingLeft: "5.5vw", marginTop: "1.5vw"}}>
            <Link style={{color: 'inherit', textDecoration: 'inherit'}} to="/">
                <Image className="headerImg" src="https://fanstandblobs.blob.core.windows.net/images/fanstandLogoRounded.png"/>
                
                <span style={{color: 'white', fontSize: '5vw', marginLeft: "6vw"}}> Fanstand</span>
            </Link>
        </Col>  
    )
};

const MobileHeader = () => {
    return (
        <>
            <header className="header">
                <Row className="correctSizedRow">
                    <MobileFanstandLogo />
                    <Col style={{display: 'flex', flexDirection: 'column-reverse', justifyContent: "bottom"}}>
                        <nav>
                            <ul style={{margin: "0 auto"}}>                                
                                <li>
                                    <Link style={{color: 'inherit', textDecoration: 'inherit', fontSize: '3vw'}}
                                         to="/about">
                                    <div className="headerLink">
                                        About
                                    </div>
                                </Link>
                                </li>
                            </ul>
                        </nav>
                    </Col>
                </Row>
            </header>
        </>
    );
};

export default MobileHeader;